import { Link, graphql, useStaticQuery } from "gatsby";
import { useMemo } from "react";
import reactStringReplace from "react-string-replace";

const REGEX_WORD = /\w+/g;

function countWords(str) {
  return str ? str.match(REGEX_WORD).length : 0;
}

/**
 * Convert a string or list to a list of either string or <Link> elements
 *
 * Ex: "This is a Frida test" => ["This is a ", <Link to="/frida">Frida</Link>, " test"]
 *
 * - We want to match the most precise match in priority
 * Ex: if the string is "wonderful Frida Silver earring" and we have both "Frida" and "Friday Silver"
 * we want to match the later.
 */
export default function useSEOSentenceToLinkConversion(content = "", options = {}) {
  const { allContentfulSeoDescriptionLinks } = useStaticQuery(graphql`
    query useSEOSentenceToLinkConversion {
      allContentfulSeoDescriptionLinks {
        edges {
          node {
            word
            link
          }
        }
      }
    }
  `);
  const sortedSEOLinks = useMemo(
    () =>
      allContentfulSeoDescriptionLinks.edges
        // Convert GraphQL format
        .map(({ node }) => node)
        // Sort SEO text to be replaced from the one with the most words to the one with the least
        .sort(({ word: wordA }, { word: wordB }) => countWords(wordB) - countWords(wordA)),
    [allContentfulSeoDescriptionLinks]
  );
  const contentWithSEO = useMemo(
    () =>
      sortedSEOLinks.reduce(
        (acc, { word, link }) =>
          reactStringReplace(acc, new RegExp(`(${word})`, "i"), (match, i, offset) => (
            <Link
              key={`${word.split(/\s/).join("_")}-${content.length}-${offset}-${i}`}
              className={options.noStyles ? "" : "underline"} // Applying or removing underline
              to={link}
            >
              {match}
            </Link>
          )),
        content
      ),
    [content, sortedSEOLinks]
  );
  return contentWithSEO;
}

export function TextWithSEO({ children }) {
  const textWithSEO = useSEOSentenceToLinkConversion(children);

  return textWithSEO;
}
