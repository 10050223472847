import { Swiper, SwiperSlide } from "swiper/react";
import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import UpSellItem from "./upSells/UpSellItem";
import UpSellItemPlaceholder from "./upSells/UpSellItemPlaceholder";
import IconArrowCircleSm from "../icons/svgs/arrow-circle-sm.svg";

import "./upSells/styles.scss";

function UpSells({
  title,
  products = [],
  isLoading,
  noNavigation = false,
  recommendationId = "",
  slidesPerView,
}) {
  const dict = useLocalizedSentenceDict();
  const noProducts = !products.length && !isLoading;

  const swiperSettings = {
    slidesPerView,
  };

  if (!noNavigation) {
    swiperSettings.navigation = {
      nextEl: ".upsells_header__buttons-next",
      prevEl: ".upsells_header__buttons-prev",
    };
  }

  if (noProducts) {
    return null;
  }

  return (
    <div className="upsells">
      <div className="upsells_header">
        <p className="upsells_header__title">{dict.get(title)}</p>
        <div className="upsells_header__buttons">
          {!noNavigation && (
            <>
              <button type="button" className="upsells_header__buttons-prev">
                <IconArrowCircleSm width="21" fill="none" stroke="#2D2927" />
              </button>
              <button type="button" className="upsells_header__buttons-next">
                <IconArrowCircleSm width="21" fill="none" stroke="#2D2927" />
              </button>
            </>
          )}
        </div>
      </div>
      <div className="upsells_items">
        {isLoading ? (
          <UpSellItemPlaceholder />
        ) : (
          <Swiper
            navigation={swiperSettings.navigation}
            slidesPerView={swiperSettings.slidesPerView}
          >
            {products.map((product) => (
              <SwiperSlide key={product.handle}>
                <UpSellItem
                  recommendationId={recommendationId}
                  product={product}
                  key={product.handle}
                  atcButtonText={product?._upsell?.atcButtonText}
                  atcButtonDisabled={product?._upsell?.atcButtonDisabled}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </div>
  );
}

UpSells.propTypes = {
  title: PropTypes.string,
  isLoading: PropTypes.bool,
  products: PropTypes.array,
  recommendationId: PropTypes.string,
  noNavigation: PropTypes.bool,
  slidesPerView: PropTypes.number,
};

export default withALErrorBoundary({
  name: "UpSells",
  priority: "P3",
})(UpSells);
