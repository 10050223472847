import { useMemo, useState } from "react";
import PropTypes from "prop-types";
import { withALErrorBoundary } from "../../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentCountryCode } from "../../../hooks/usePrices";
import { Country } from "../../../constants/Country";
import MenuContainer from "./menu/MenuContainer";
import { Link } from "../../al_components/ALLink";

import "./menu/styles.scss";

function MenuTitleText({ menuCategories, children, ...props }) {
  const isSingleMenuItem = menuCategories?.length === 1 && menuCategories[0].links.length === 1;
  if (isSingleMenuItem) {
    const [item] = menuCategories[0].links;
    const destinationUrl = item.title === "empty" ? menuCategories[0].titleLink : item.link.link;
    return (
      <Link className={props.className} style={props.style} to={destinationUrl}>
        {children}
      </Link>
    );
  }
  return <button {...props}>{children}</button>;
}

function Menu({ menu, showMenu }) {
  const { title: menuTitle, textColor: color, menuContainer } = menu;
  const { id, menuCategories, imageLinks } = menuContainer[0];
  const [isExpanded, setIsExpanded] = useState(false);
  const isSingleMenuItem = menuCategories?.length === 1 && menuCategories[0].links.length === 1;
  const countryCode = useCurrentCountryCode();
  const isUSCountry = countryCode === Country.US;

  // Filter out AL Rewards for US and AL Luxe for non-US
  const filteredImageLinks = useMemo(
    () =>
      imageLinks?.filter(
        (imageLink) =>
          (isUSCountry && imageLink.title !== "AL Rewards") ||
          (!isUSCountry && imageLink.title !== "AL Luxe")
      ),
    [imageLinks, isUSCountry]
  );

  return (
    <div className="header_left_menu_item__container" onMouseEnter={showMenu}>
      <MenuTitleText
        menuCategories={menuCategories}
        className={`header_left_menu_item header_left_menu_item_${menuTitle
          .replace(" ", "")
          .toLowerCase()}`}
        aria-expanded={isExpanded}
        style={{ color, textDecorationColor: color }}
        type="button"
        onMouseEnter={() => setIsExpanded(!isSingleMenuItem)}
        onMouseLeave={() => setIsExpanded(false)}
        onFocus={() => setIsExpanded(!isSingleMenuItem)}
        onBlur={() => setIsExpanded(false)}
      >
        <h3 className="microtext microtext--bold microtext--uc" style={{ color }}>
          {menuTitle}
        </h3>
      </MenuTitleText>
      <div
        className={`header_left_menu_content navMenu__containers ${
          filteredImageLinks && filteredImageLinks.length <= 3
            ? "header_left_menu_content__short"
            : ""
        }`}
        key={menu.id}
        aria-expanded={isExpanded}
        role="menuitem"
        onMouseEnter={() => setIsExpanded(!isSingleMenuItem)}
        onMouseLeave={() => setIsExpanded(false)}
        onFocus={() => setIsExpanded(!isSingleMenuItem)}
        onBlur={() => setIsExpanded(false)}
        tabIndex="-1"
      >
        <MenuContainer
          key={id}
          categories={menuCategories}
          imageLinks={filteredImageLinks}
          menuTitle={menuTitle}
          closeMenu={() => setIsExpanded(false)}
        />
      </div>
    </div>
  );
}

Menu.propTypes = {
  showMenu: PropTypes.func,
  menu: PropTypes.shape({
    id: PropTypes.string,
    textColor: PropTypes.string,
    title: PropTypes.string,
    menuContainer: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        imageLinks: PropTypes.array,
        menuCategories: PropTypes.array,
      })
    ),
  }),
};

export default withALErrorBoundary({
  name: "Menu",
  priority: "P2",
})(Menu);
