const locales = [
  {
    locale: "en-US",
    countryName: "United States",
    country: "US",
    currency: "USD",
    currencySymbol: "$",
    iso3: "USA",
    timeZone: "America/New_York",
  },
  {
    locale: "en-US",
    countryName: "British Virgin Islands",
    country: "VG",
    currency: "USD",
    currencySymbol: "$",
    iso3: "VGB",
    timeZone: "America/Tortola",
  },
  {
    locale: "en-US",
    countryName: "Turks and Caicos Islands",
    country: "TC",
    currency: "USD",
    currencySymbol: "$",
    iso3: "TCA",
    timeZone: "\tAmerica/Grand_Turk",
  },
  {
    locale: "fr",
    countryName: "French Guiana",
    country: "GF",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "GUF",
    timeZone: "America/Cayenne",
  },
  {
    locale: "fr",
    countryName: "French Polynesia",
    country: "PF",
    currency: "XPF",
    currencySymbol: "CFPF",
    iso3: "PYF",
    timeZone: "\tPacific/Tahiti",
  },
  {
    locale: "ca",
    value: "Canada",
    countryName: "Canada",
    country: "CA",
    currency: "CAD",
    currencySymbol: "CA$",
    iso3: "CAN",
    timeZone: "America/New_York",
  },
  {
    locale: "en-US",
    countryName: "Albania",
    country: "AL",
    currency: "ALL",
    currencySymbol: "ALL",
    iso3: "ALB",
    timeZone: "Europe/Tirane",
  },
  {
    locale: "en-US",
    countryName: "Algeria",
    country: "DZ",
    currency: "DZD",
    currencySymbol: "DA",
    iso3: "DZA",
    timeZone: "Africa/Algiers",
  },
  {
    locale: "en-US",
    countryName: "Andorra",
    country: "AD",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "AND",
    timeZone: "Europe/Andorra",
  },
  {
    locale: "en-US",
    countryName: "Anguilla",
    country: "AI",
    currency: "XCD",
    currencySymbol: "EC$",
    iso3: "AIA",
    timeZone: "\tAmerica/Anguilla",
  },
  {
    locale: "en-US",
    countryName: "Antigua and Barbuda",
    country: "AG",
    currency: "XCD",
    currencySymbol: "EC$",
    iso3: "ATG",
    timeZone: "America/Antigua",
  },
  {
    locale: "en-US",
    countryName: "Argentina",
    country: "AR",
    currency: "ARS",
    currencySymbol: "AR$",
    iso3: "ARG",
    timeZone: "America/Argentina/Buenos_Aires",
  },
  {
    locale: "en-US",
    countryName: "Armenia",
    country: "AM",
    currency: "AMD",
    currencySymbol: "AMD",
    iso3: "ARM",
    timeZone: "Asia/Yerevan",
  },
  {
    locale: "en-US",
    countryName: "Aruba",
    country: "AW",
    currency: "AWG",
    currencySymbol: "Afl.",
    iso3: "ABW",
    timeZone: "America/Aruba",
  },
  {
    locale: "en-US",
    countryName: "Azerbaijan",
    country: "AZ",
    currency: "AZN",
    currencySymbol: "man.",
    iso3: "AZE",
    timeZone: "Asia/Baku",
  },
  {
    locale: "en-US",
    countryName: "Bahamas",
    country: "BS",
    currency: "BSD",
    currencySymbol: "BS$",
    iso3: "BHS",
    timeZone: "America/Nassau",
  },
  {
    locale: "en-US",
    countryName: "Bangladesh",
    country: "BD",
    currency: "BDT",
    currencySymbol: "Tk",
    iso3: "BGD",
    timeZone: "Asia/Dhaka",
  },
  {
    locale: "en-US",
    countryName: "Barbados",
    country: "BB",
    currency: "BBD",
    currencySymbol: "Bds$",
    iso3: "BRB",
    timeZone: "America/Barbados",
  },
  {
    locale: "en-US",
    countryName: "Belize",
    country: "BZ",
    currency: "BZD",
    currencySymbol: "BZ$",
    iso3: "BLZ",
    timeZone: "America/Belize",
  },
  {
    locale: "en-US",
    countryName: "Benin",
    country: "BJ",
    currency: "XOF",
    currencySymbol: "CFA",
    iso3: "BEN",
    timeZone: "Africa/Porto-Novo",
  },
  {
    locale: "en-US",
    countryName: "Bermuda",
    country: "BM",
    currency: "BMD",
    currencySymbol: "BD$",
    iso3: "BMU",
    timeZone: "Atlantic/Bermuda",
  },
  {
    locale: "en-US",
    countryName: "Bolivia",
    country: "BO",
    currency: "BOB",
    currencySymbol: "Bs",
    iso3: "BOL",
    timeZone: "America/La_Paz",
  },
  {
    locale: "en-US",
    countryName: "Bosnia and Herzegovina",
    country: "BA",
    currency: "BAM",
    currencySymbol: "KM",
    iso3: "BIH",
    timeZone: "Europe/Sarajevo",
  },
  {
    locale: "en-US",
    countryName: "Botswana",
    country: "BW",
    currency: "BWP",
    currencySymbol: "BWP",
    iso3: "BWA",
    timeZone: "Africa/Gaborone",
  },
  {
    locale: "en-US",
    countryName: "Brunei Darussalam",
    country: "BN",
    currency: "BND",
    currencySymbol: "BN$",
    iso3: "BRN",
    timeZone: "Asia/Brunei",
  },
  {
    locale: "en-US",
    countryName: "Cambodia",
    country: "KH",
    currency: "KHR",
    currencySymbol: "KHR",
    iso3: "KHM",
    timeZone: "Asia/Phnom_Penh",
  },
  {
    locale: "en-US",
    countryName: "Cape Verde",
    country: "CV",
    currency: "CVE",
    currencySymbol: "CV$",
    iso3: "CPV",
    timeZone: "Atlantic/Cape_Verde",
  },
  {
    locale: "en-US",
    countryName: "Cayman Islands",
    country: "KY",
    currency: "KYD",
    currencySymbol: "KY$",
    iso3: "CYM",
    timeZone: "America/Cayman",
  },
  {
    locale: "en-US",
    countryName: "Chile",
    country: "CL",
    currency: "CLP",
    currencySymbol: "CL$",
    iso3: "CHL",
    timeZone: "America/Santiago",
  },
  {
    locale: "en-US",
    countryName: "Colombia",
    country: "CO",
    currency: "COP",
    currencySymbol: "COP",
    iso3: "COL",
    timeZone: "America/Bogota",
  },
  {
    locale: "en-US",
    countryName: "Comoros",
    country: "KM",
    currency: "KMF",
    currencySymbol: "CF",
    iso3: "COM",
    timeZone: "Indian/Comoro",
  },
  {
    locale: "en-US",
    countryName: "Cook Islands",
    country: "CK",
    currency: "NZD",
    currencySymbol: "NZ$",
    iso3: "COK",
    timeZone: "Pacific/Rarotonga",
  },
  {
    locale: "en-US",
    countryName: "Costa Rica",
    country: "CR",
    currency: "CRC",
    currencySymbol: "₡",
    iso3: "CRI",
    timeZone: "America/Costa_Rica",
  },
  {
    locale: "en-US",
    countryName: "Denmark",
    country: "DK",
    currency: "DKK",
    currencySymbol: "kr",
    iso3: "DNK",
    timeZone: "Europe/Copenhagen",
  },
  {
    locale: "en-US",
    countryName: "Djibouti",
    country: "DJ",
    currency: "DJF",
    currencySymbol: "Fdj",
    iso3: "DJI",
    timeZone: "Africa/Djibouti",
  },
  {
    locale: "en-US",
    countryName: "Dominica",
    country: "DM",
    currency: "XCD",
    currencySymbol: "EC$",
    iso3: "DMA",
    timeZone: "America/Dominica",
  },
  {
    locale: "en-US",
    countryName: "Dominican Republic",
    country: "DO",
    currency: "DOP",
    currencySymbol: "RD$",
    iso3: "DOM",
    timeZone: "America/Santo_Domingo",
  },
  {
    locale: "en-US",
    countryName: "Ecuador",
    country: "EC",
    currency: "USD",
    currencySymbol: "$",
    iso3: "ECU",
    timeZone: "America/Guayaquil",
  },
  {
    locale: "en-US",
    countryName: "Egypt",
    country: "EG",
    currency: "EGP",
    currencySymbol: "EGP",
    iso3: "EGY",
    timeZone: "Africa/Cairo",
  },
  {
    locale: "en-US",
    countryName: "El Salvador",
    country: "SV",
    currency: "USD",
    currencySymbol: "$",
    iso3: "SLV",
    timeZone: "America/El_Salvador",
  },
  {
    locale: "en-US",
    countryName: "Ethiopia",
    country: "ET",
    currency: "ETB",
    currencySymbol: "Br",
    iso3: "ETH",
    timeZone: "Africa/Addis_Ababa",
  },
  {
    locale: "en-US",
    countryName: "Falkland Islands",
    country: "FK",
    currency: "FKP",
    currencySymbol: "FK£",
    iso3: "FLK",
    timeZone: "Atlantic/Stanley",
  },
  {
    locale: "en-US",
    countryName: "Faroe Islands",
    country: "FO",
    currency: "DKK",
    currencySymbol: "kr",
    iso3: "FRO",
    timeZone: "Atlantic/Faroe",
  },
  {
    locale: "en-US",
    countryName: "Fiji",
    country: "FJ",
    currency: "FJD",
    currencySymbol: "FJ$",
    iso3: "FJI",
    timeZone: "Pacific/Fiji",
  },
  {
    locale: "en-US",
    countryName: "Georgia",
    country: "GE",
    currency: "GEL",
    currencySymbol: "GEL",
    iso3: "GEO",
    timeZone: "Asia/Tbilisi",
  },
  {
    locale: "en-US",
    countryName: "Gibraltar",
    country: "GI",
    currency: "GBP",
    currencySymbol: "£",
    iso3: "GIB",
    timeZone: "Europe/Gibraltar",
  },
  {
    locale: "en-US",
    countryName: "Greenland",
    country: "GL",
    currency: "DKK",
    currencySymbol: "kr",
    iso3: "GRL",
    timeZone: "America/Thule",
  },
  {
    locale: "en-US",
    countryName: "Grenada",
    country: "GD",
    currency: "XCD",
    currencySymbol: "EC$",
    iso3: "GRD",
    timeZone: "America/Grenada",
  },
  {
    locale: "en-US",
    countryName: "Guadeloupe",
    country: "GP",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "GLP",
    timeZone: "America/Guadeloupe",
  },
  {
    locale: "en-US",
    countryName: "Guatemala",
    country: "GT",
    currency: "GTQ",
    currencySymbol: "GTQ",
    iso3: "GTM",
    timeZone: "America/Guatemala",
  },
  {
    locale: "en-US",
    countryName: "Guernsey",
    country: "GG",
    currency: "GBP",
    currencySymbol: "£",
    iso3: "GGY",
    timeZone: "Europe/Guernsey",
  },
  {
    locale: "en-US",
    countryName: "Guinea",
    country: "GN",
    currency: "GNF",
    currencySymbol: "FG",
    iso3: "GIN",
    timeZone: "Africa/Conakry",
  },
  {
    locale: "en-US",
    countryName: "Guinea-Bissau",
    country: "GW",
    currency: "XOF",
    currencySymbol: "CFA",
    iso3: "GNB",
    timeZone: "Africa/Bissau",
  },
  {
    locale: "en-US",
    countryName: "Guyana",
    country: "GY",
    currency: "GYD",
    currencySymbol: "GY$",
    iso3: "GUY",
    timeZone: "America/Guyana",
  },
  {
    locale: "en-US",
    countryName: "Haiti",
    country: "HT",
    currency: "HTG",
    currencySymbol: "HTG",
    iso3: "HTI",
    timeZone: "America/Port-au-Prince",
  },
  {
    locale: "en-US",
    countryName: "Honduras",
    country: "HN",
    currency: "HNL",
    currencySymbol: "HNL",
    iso3: "HND",
    timeZone: "America/Tegucigalpa",
  },
  {
    locale: "en-US",
    countryName: "Hong Kong",
    country: "HK",
    currency: "HKD",
    currencySymbol: "HK$",
    iso3: "HKG",
    timeZone: "Asia/Hong_Kong",
  },
  {
    locale: "en-US",
    countryName: "Iceland",
    country: "IS",
    currency: "ISK",
    currencySymbol: "kr",
    iso3: "ISL",
    timeZone: "Atlantic/Reykjavik",
  },
  {
    locale: "en-US",
    countryName: "Indonesia",
    country: "ID",
    currency: "IDR",
    currencySymbol: "Rp",
    iso3: "IDN",
    timeZone: "Asia/Jakarta",
  },
  {
    locale: "en-US",
    countryName: "Israel",
    country: "IL",
    currency: "ILS",
    currencySymbol: "₪",
    iso3: "ISR",
    timeZone: "Asia/Jerusalem",
  },
  {
    locale: "en-US",
    countryName: "Jamaica",
    country: "JM",
    currency: "JMD",
    currencySymbol: "J$",
    iso3: "JAM",
    timeZone: "America/Jamaica",
  },
  {
    locale: "en-US",
    countryName: "Japan",
    country: "JP",
    currency: "JPY",
    currencySymbol: "¥",
    iso3: "JPN",
    timeZone: "Asia/Tokyo",
  },
  {
    locale: "en-US",
    countryName: "Kazakhstan",
    country: "KZ",
    currency: "KZT",
    currencySymbol: "KZT",
    iso3: "KAZ",
    timeZone: "Asia/Almaty",
  },
  {
    locale: "en-US",
    countryName: "Kenya",
    country: "KE",
    currency: "KES",
    currencySymbol: "KSh",
    iso3: "KEN",
    timeZone: "Africa/Nairobi",
  },
  {
    locale: "en-US",
    countryName: "Korea (South)",
    country: "KR",
    currency: "KRW",
    currencySymbol: "₩",
    iso3: "KOR",
    timeZone: "Asia/Seoul",
  },
  {
    locale: "en-US",
    countryName: "Kyrgyzstan",
    country: "KG",
    currency: "KGS",
    currencySymbol: "KGS",
    iso3: "KGZ",
    timeZone: "Asia/Bishkek",
  },
  {
    locale: "en-US",
    countryName: "Laos",
    country: "LA",
    currency: "LAK",
    currencySymbol: "₭",
    iso3: "LAO",
    timeZone: "Asia/Vientiane",
  },
  {
    locale: "en-US",
    countryName: "Lebanon",
    country: "LB",
    currency: "LBP",
    currencySymbol: "LBP",
    iso3: "LBN",
    timeZone: "Asia/Beirut",
  },
  {
    locale: "en-US",
    countryName: "Lesotho",
    country: "LS",
    currency: "LSL",
    currencySymbol: "LSL",
    iso3: "LSO",
    timeZone: "Africa/Maseru",
  },
  {
    locale: "en-US",
    countryName: "Liechtenstein",
    country: "LI",
    currency: "CHF",
    currencySymbol: "CHF",
    iso3: "LIE",
    timeZone: "Europe/Vaduz",
  },
  {
    locale: "en-US",
    countryName: "Macau",
    country: "MO",
    currency: "MOP",
    currencySymbol: "MOP$",
    iso3: "MAC",
    timeZone: "Asia/Macau",
  },
  {
    locale: "en-US",
    countryName: "Malawi",
    country: "MW",
    currency: "MWK",
    currencySymbol: "MWK",
    iso3: "MWI",
    timeZone: "Africa/Blantyre",
  },
  {
    locale: "en-US",
    countryName: "Malaysia",
    country: "MY",
    currency: "MYR",
    currencySymbol: "RM",
    iso3: "MYS",
    timeZone: "Asia/Kuala_Lumpur",
  },
  {
    locale: "en-US",
    countryName: "Maldives",
    country: "MV",
    currency: "MVR",
    currencySymbol: "MVR",
    iso3: "MDV",
    timeZone: "Indian/Maldives",
  },
  {
    locale: "en-US",
    countryName: "Martinique",
    country: "MQ",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "MTQ",
    timeZone: "America/Martinique",
  },
  {
    locale: "en-US",
    countryName: "Mauritius",
    country: "MU",
    currency: "MUR",
    currencySymbol: "MURs",
    iso3: "MUS",
    timeZone: "Indian/Mauritius",
  },
  {
    locale: "en-US",
    countryName: "Mexico",
    country: "MX",
    currency: "MXN",
    currencySymbol: "Mex$",
    iso3: "MEX",
    timeZone: "America/Mexico_City",
  },
  {
    locale: "en-US",
    countryName: "Moldova",
    country: "MD",
    currency: "MDL",
    currencySymbol: "MDL",
    iso3: "MDA",
    timeZone: "Europe/Chisinau",
  },
  {
    locale: "en-US",
    countryName: "Monaco",
    country: "MC",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "MCO",
    timeZone: "Europe/Monaco",
  },
  {
    locale: "en-US",
    countryName: "Mongolia",
    country: "MN",
    currency: "MNT",
    currencySymbol: "₮",
    iso3: "MNG",
    timeZone: "Asia/Ulaanbaatar",
  },
  {
    locale: "en-US",
    countryName: "Montenegro",
    country: "ME",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "MNE",
    timeZone: "Europe/Podgorica",
  },
  {
    locale: "en-US",
    countryName: "Montserrat",
    country: "MS",
    currency: "XCD",
    currencySymbol: "EC$",
    iso3: "MSR",
    timeZone: "America/Montserrat",
  },
  {
    locale: "en-US",
    countryName: "Morocco",
    country: "MA",
    currency: "MAD",
    currencySymbol: "MAD",
    iso3: "MAR",
    timeZone: "Africa/Casablanca",
  },
  {
    locale: "en-US",
    countryName: "Mozambique",
    country: "MZ",
    currency: "MZN",
    currencySymbol: "MTn",
    iso3: "MOZ",
    timeZone: "Africa/Maputo",
  },
  {
    locale: "en-US",
    countryName: "Nauru",
    country: "NR",
    currency: "AUD",
    currencySymbol: "AU$",
    iso3: "NRU",
    timeZone: "Pacific/Nauru",
  },
  {
    locale: "en-US",
    countryName: "Nepal",
    country: "NP",
    currency: "NPR",
    currencySymbol: "NPRs",
    iso3: "NPL",
    timeZone: "Asia/Kathmandu",
  },
  {
    locale: "en-US",
    countryName: "New Caledonia",
    country: "NC",
    currency: "XPF",
    currencySymbol: "CFPF",
    iso3: "NCL",
    timeZone: "Pacific/Noumea",
  },
  {
    locale: "en-US",
    countryName: "Nicaragua",
    country: "NI",
    currency: "NIO",
    currencySymbol: "NIO",
    iso3: "NIC",
    timeZone: "America/Managua",
  },
  {
    locale: "en-US",
    countryName: "Nigeria",
    country: "NG",
    currency: "NGN",
    currencySymbol: "₦",
    iso3: "NGA",
    timeZone: "Africa/Lagos",
  },
  {
    locale: "en-US",
    countryName: "Niue",
    country: "NU",
    currency: "NZD",
    currencySymbol: "NZ$",
    iso3: "NIU",
    timeZone: "Pacific/Niue",
  },
  {
    locale: "en-US",
    countryName: "North Macedonia",
    country: "MK",
    currency: "MKD",
    currencySymbol: "MKD",
    iso3: "MKD",
    timeZone: "Europe/Skopje",
  },
  {
    locale: "en-US",
    countryName: "Norway",
    country: "NO",
    currency: "NOK",
    currencySymbol: "kr",
    iso3: "NOR",
    timeZone: "Europe/Oslo",
  },
  {
    locale: "en-US",
    countryName: "Pakistan",
    country: "PK",
    currency: "PKR",
    currencySymbol: "Rs",
    iso3: "PAK",
    timeZone: "Asia/Karachi",
  },
  {
    locale: "en-US",
    countryName: "Panama",
    country: "PA",
    currency: "USD",
    currencySymbol: "$",
    iso3: "PAN",
    timeZone: "America/Panama",
  },
  {
    locale: "en-US",
    countryName: "Papua New Guinea",
    country: "PG",
    currency: "PGK",
    currencySymbol: "PGK",
    iso3: "PNG",
    timeZone: "Pacific/Port_Moresby",
  },
  {
    locale: "en-US",
    countryName: "Paraguay",
    country: "PY",
    currency: "PYG",
    currencySymbol: "₲",
    iso3: "PRY",
    timeZone: "America/Asuncion",
  },
  {
    locale: "en-US",
    countryName: "Peru",
    country: "PE",
    currency: "PEN",
    currencySymbol: "S/.",
    iso3: "PER",
    timeZone: "America/Lima",
  },
  {
    locale: "en-US",
    countryName: "Philippines",
    country: "PH",
    currency: "PHP",
    currencySymbol: "₱",
    iso3: "PHL",
    timeZone: "\tAsia/Manila",
  },
  {
    locale: "en-US",
    countryName: "Qatar",
    country: "QA",
    currency: "QAR",
    currencySymbol: "QR",
    iso3: "QAT",
    timeZone: "Asia/Qatar",
  },
  {
    locale: "en-US",
    countryName: "Reunion",
    country: "RE",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "REU",
    timeZone: "Indian/Reunion",
  },
  {
    locale: "en-US",
    countryName: "Saint Kitts and Nevis",
    country: "KN",
    currency: "XCD",
    currencySymbol: "EC$",
    iso3: "KNA",
    timeZone: "America/St_Kitts",
  },
  {
    locale: "en-US",
    countryName: "Saint Lucia",
    country: "LC",
    currency: "XCD",
    currencySymbol: "EC$",
    iso3: "LCA",
    timeZone: "America/St_Lucia",
  },
  {
    locale: "en-US",
    countryName: "Samoa",
    country: "WS",
    currency: "WST",
    currencySymbol: "WS$",
    iso3: "WSM",
    timeZone: "Pacific/Apia",
  },
  {
    locale: "en-US",
    countryName: "San Marino",
    country: "SM",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "SMR",
    timeZone: "Europe/San_Marino",
  },
  {
    locale: "en-US",
    countryName: "Sao Tome and Principe",
    country: "ST",
    currency: "STD",
    currencySymbol: "Db",
    iso3: "STP",
    timeZone: "Africa/Sao_Tome",
  },
  {
    locale: "en-US",
    countryName: "Saudi Arabia",
    country: "SA",
    currency: "SAR",
    currencySymbol: "SAR",
    iso3: "SAU",
    timeZone: "Asia/Riyadh",
  },
  {
    locale: "en-US",
    countryName: "Serbia",
    country: "RS",
    currency: "RSD",
    currencySymbol: "din.",
    iso3: "SRB",
    timeZone: "Europe/Belgrade",
  },
  {
    locale: "en-US",
    countryName: "Seychelles",
    country: "SC",
    currency: "SCR",
    currencySymbol: "SRe",
    iso3: "SYC",
    timeZone: "Indian/Mahe",
  },
  {
    locale: "en-US",
    countryName: "Singapore",
    country: "SG",
    currency: "SGD",
    currencySymbol: "S$",
    iso3: "SGP",
    timeZone: "Asia/Singapore",
  },
  {
    locale: "en-US",
    countryName: "Solomon Islands",
    country: "SB",
    currency: "SBD",
    currencySymbol: "SI$",
    iso3: "SLB",
    timeZone: "Pacific/Guadalcanal",
  },
  {
    locale: "en-US",
    countryName: "South Africa",
    country: "ZA",
    currency: "ZAR",
    currencySymbol: "R",
    iso3: "ZAF",
    timeZone: "Africa/Johannesburg",
  },
  {
    locale: "en-US",
    countryName: "Sri Lanka",
    country: "LK",
    currency: "LKR",
    currencySymbol: "SLRs",
    iso3: "LKA",
    timeZone: "Asia/Colombo",
  },
  {
    locale: "en-US",
    countryName: "St Barthelemy",
    country: "BL",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "BLM",
    timeZone: "America/St_Barthelemy",
  },
  {
    locale: "en-US",
    countryName: "Suriname",
    country: "SR",
    currency: "SRD",
    currencySymbol: "SR$",
    iso3: "SUR",
    timeZone: "America/Paramaribo",
  },
  {
    locale: "en-US",
    countryName: "Swaziland",
    country: "SZ",
    currency: "SZL",
    currencySymbol: "SZL",
    iso3: "SWZ",
    timeZone: "Africa/Mbabane",
  },
  {
    locale: "en-US",
    countryName: "Taiwan",
    country: "TW",
    currency: "TWD",
    currencySymbol: "NT$",
    iso3: "TWN",
    timeZone: "Asia/Taipei",
  },
  {
    locale: "en-US",
    countryName: "Tanzania",
    country: "TZ",
    currency: "TZS",
    currencySymbol: "TSh",
    iso3: "TZA",
    timeZone: "Africa/Dar_es_Salaam",
  },
  {
    locale: "en-US",
    countryName: "Thailand",
    country: "TH",
    currency: "THB",
    currencySymbol: "฿",
    iso3: "THA",
    timeZone: "Asia/Bangkok",
  },
  {
    locale: "en-US",
    countryName: "Tonga",
    country: "TO",
    currency: "TOP",
    currencySymbol: "T$",
    iso3: "TON",
    timeZone: "Pacific/Tongatapu",
  },
  {
    locale: "en-US",
    countryName: "Trinidad and Tobago",
    country: "TT",
    currency: "TTD",
    currencySymbol: "TT$",
    iso3: "TTO",
    timeZone: "America/Port_of_Spain",
  },
  {
    locale: "en-US",
    countryName: "Turkey",
    country: "TR",
    currency: "TRY",
    currencySymbol: "TL",
    iso3: "TUR",
    timeZone: "Europe/Istanbul",
  },
  {
    locale: "en-US",
    countryName: "Tuvalu",
    country: "TV",
    currency: "AUD",
    currencySymbol: "AU$",
    iso3: "TUV",
    timeZone: "Pacific/Funafuti",
  },
  {
    locale: "en-US",
    countryName: "United Arab Emirates",
    country: "AE",
    currency: "AED",
    currencySymbol: "AED",
    iso3: "ARE",
    timeZone: "Asia/Dubai",
  },
  {
    locale: "en-US",
    countryName: "Uruguay",
    country: "UY",
    currency: "UYU",
    currencySymbol: "$U",
    iso3: "URY",
    timeZone: "America/Montevideo",
  },
  {
    locale: "en-US",
    countryName: "Uzbekistan",
    country: "UZ",
    currency: "UZS",
    currencySymbol: "UZS",
    iso3: "UZB",
    timeZone: "Asia/Tashkent",
  },
  {
    locale: "en-US",
    countryName: "Vanuatu",
    country: "VU",
    currency: "VUV",
    currencySymbol: "VT",
    iso3: "VUT",
    timeZone: "Pacific/Efate",
  },
  {
    locale: "en-US",
    countryName: "Vatican City State (Holy See)",
    country: "VA",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "VAT",
    timeZone: "Europe/Vatican",
  },
  {
    locale: "en-US",
    countryName: "Venezuela",
    country: "VE",
    currency: "USD",
    currencySymbol: "$",
    iso3: "VEN",
    timeZone: "America/Caracas",
  },
  {
    locale: "en-US",
    countryName: "Namibia",
    country: "NA",
    currency: "NAD",
    currencySymbol: "N$",
    iso3: "NAM",
    timeZone: "Africa/Windhoek",
  },
  {
    locale: "en-US",
    countryName: "Zambia",
    country: "ZM",
    currency: "ZMW",
    currencySymbol: "ZMW",
    iso3: "ZMB",
    timeZone: "Africa/Lusaka",
  },
  {
    locale: "en-US",
    countryName: "Zimbabwe",
    country: "ZW",
    currency: "USD",
    currencySymbol: "$",
    iso3: "ZWE",
    timeZone: "Africa/Harare",
  },
  {
    locale: "en-US",
    countryName: "Australia",
    country: "AU",
    currency: "AUD",
    currencySymbol: "AU$",
    iso3: "AUS",
    timeZone: "Australia/Sydney",
  },
  {
    locale: "en-US",
    countryName: "Austria",
    country: "AT",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "AUT",
    timeZone: "Europe/Vienna",
  },
  {
    locale: "en-US",
    countryName: "Belgium",
    country: "BE",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "BEL",
    timeZone: "Europe/Brussels",
  },
  {
    locale: "en-US",
    countryName: "Bulgaria",
    country: "BG",
    currency: "BGN",
    currencySymbol: "BGN",
    iso3: "BGR",
    timeZone: "Europe/Sofia",
  },
  {
    locale: "en-US",
    countryName: "Croatia",
    country: "HR",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "HRV",
    timeZone: "Europe/Zagreb",
  },
  {
    locale: "en-US",
    countryName: "Cyprus",
    country: "CY",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "CYP",
    timeZone: "Asia/Nicosia",
  },
  {
    locale: "en-US",
    countryName: "Czech Republic",
    country: "CZ",
    currency: "CZK",
    currencySymbol: "Kč",
    iso3: "CZE",
    timeZone: "Europe/Prague",
  },
  {
    locale: "en-US",
    countryName: "Estonia",
    country: "EE",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "EST",
    timeZone: "Europe/Tallinn",
  },
  {
    locale: "en-US",
    countryName: "Finland",
    country: "FI",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "FIN",
    timeZone: "Europe/Helsinki",
  },
  {
    locale: "fr",
    countryName: "France",
    country: "FR",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "FRA",
    timeZone: "Europe/Paris",
  },
  {
    locale: "de",
    countryName: "Germany",
    country: "DE",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "DEU",
    timeZone: "Europe/Berlin",
  },
  {
    locale: "en-US",
    countryName: "Greece",
    country: "GR",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "GRC",
    timeZone: "Europe/Athens",
  },
  {
    locale: "en-US",
    countryName: "Hungary",
    country: "HU",
    currency: "HUF",
    currencySymbol: "Ft",
    iso3: "HUN",
    timeZone: "Europe/Budapest",
  },
  {
    locale: "en-US",
    countryName: "Ireland (Republic of)",
    country: "IE",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "IRL",
    timeZone: "Europe/Dublin",
  },
  {
    locale: "en-US",
    countryName: "Italy",
    country: "IT",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "ITA",
    timeZone: "Europe/Rome",
  },
  {
    locale: "en-US",
    countryName: "Latvia",
    country: "LV",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "LVA",
    timeZone: "Europe/Riga",
  },
  {
    locale: "en-US",
    countryName: "Lithuania",
    country: "LT",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "LTU",
    timeZone: "Europe/Vilnius",
  },
  {
    locale: "en-US",
    countryName: "Luxembourg",
    country: "LU",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "LUX",
    timeZone: "Europe/Luxembourg",
  },
  {
    locale: "en-US",
    countryName: "Malta",
    country: "MT",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "MLT",
    timeZone: "Europe/Malta",
  },
  {
    locale: "en-US",
    countryName: "Netherlands",
    country: "NL",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "NLD",
    timeZone: "Europe/Amsterdam",
  },
  {
    locale: "en-US",
    countryName: "New Zealand",
    country: "NZ",
    currency: "NZD",
    currencySymbol: "NZ$",
    iso3: "NZL",
    timeZone: "Pacific/Auckland",
  },
  {
    locale: "en-US",
    countryName: "Poland",
    country: "PL",
    currency: "PLN",
    currencySymbol: "zł",
    iso3: "POL",
    timeZone: "Europe/Warsaw",
  },
  {
    locale: "en-US",
    countryName: "Romania",
    country: "RO",
    currency: "RON",
    currencySymbol: "RON",
    iso3: "ROU",
    timeZone: "Europe/Bucharest",
  },
  {
    locale: "en-US",
    countryName: "Slovak Republic",
    country: "SK",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "SVK",
    timeZone: "Europe/Bratislava",
  },
  {
    locale: "en-US",
    countryName: "Slovenia",
    country: "SI",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "SVN",
    timeZone: "Europe/Ljubljana",
  },
  {
    locale: "en-US",
    countryName: "Spain",
    country: "ES",
    currency: "EUR",
    currencySymbol: "€",
    iso3: "ESP",
    timeZone: "Europe/Madrid",
  },
  {
    locale: "en-US",
    countryName: "Sweden",
    country: "SE",
    currency: "SEK",
    currencySymbol: "kr",
    iso3: "SWE",
    timeZone: "Europe/Stockholm",
  },
  {
    locale: "en-US",
    countryName: "Switzerland",
    country: "CH",
    currency: "CHF",
    currencySymbol: "CHF",
    iso3: "CHE",
    timeZone: "Europe/Zurich",
  },
  {
    locale: "en-US",
    countryName: "United Kingdom",
    country: "GB",
    currency: "GBP",
    currencySymbol: "£",
    iso3: "GBR",
    timeZone: "Europe/London",
  },
  {
    locale: "en-US",
    countryName: "Brazil",
    country: "BR",
    currency: "BRL",
    currencySymbol: "R$",
    iso3: "BRA",
    timeZone: "America/Sao_Paulo",
  },
];
// Countries not in GE: ["PR", "BY", "TP", "UA"]

exports.locales = locales;
