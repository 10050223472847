import { isBrowser } from "../context/helpers";

const fallbackStorage = {};

function getItem(key) {
  try {
    if (!isBrowser) return null;
    if (typeof window.localStorage === "undefined" || !window.localStorage) {
      // Make sure to return null if the key is not found (undefined is not a valid value for localStorage)
      return fallbackStorage[key] || null;
    }
    return window.localStorage.getItem(key);
  } catch (e) {
    return null;
  }
}

function setItem(key, value) {
  try {
    if (!isBrowser) return;
    if (typeof window.localStorage === "undefined" || !window.localStorage) {
      fallbackStorage[key] = value;
    } else {
      window.localStorage.setItem(key, value);
    }
  } catch (e) {
    fallbackStorage[key] = value;
  }
}

function removeItem(key) {
  if (!isBrowser) return;
  if (typeof window.localStorage !== "undefined" && !!window.localStorage) {
    window.localStorage.removeItem(key);
  }
  delete fallbackStorage[key];
}

const safeLocalStorage = { getItem, setItem, removeItem };

export default safeLocalStorage;
