import { useContext, useMemo, useState } from "react";

import { GeoContext } from "../context/GeoProvider";
import { LoopReturnContext } from "../context/LoopReturnProvider";
import { CartContext } from "../context/CartProvider";
import { DiscountContext } from "../context/DiscountProvider";
import { LuxeContext } from "../context/LuxeProvider";

import { applyCartDiscounts } from "./cartDiscounts";
import { useAnnouncementBar } from "../hooks/useAnnouncementBar";
import { useShippingMessage } from "../hooks/useShippingInfo";
import { useLocalizedSentenceDict } from "../hooks/useSentenceDict";
import useIsHydrationComplete from "../hooks/useIsHydrationComplete";

import QuantityPercentProgressBar from "./al_components/QuantityPercentProgressBar";
import Timer from "./Timer";
import ABPopup from "./AnnouncementBar/ABPopup";

import DISCOUNT_TYPES from "../constants/DiscountTypes";
import { ComponentType } from "../constants/ComponentType";

import * as Styles from "./tierDiscountBottomBanner/TierDiscountBottomBanner.module.scss";
import { Country } from "../constants/Country";

function TierDiscountBottomBanner({ hidden }) {
  const { discountInfo } = useContext(DiscountContext);
  const { isLoopReturnEnabled } = useContext(LoopReturnContext);
  const { gePriceDetails } = useContext(GeoContext);
  const { cart } = useContext(CartContext);
  const { luxe } = useContext(LuxeContext);

  const dict = useLocalizedSentenceDict();
  const { hasShipping, hasTimer, hasPopup, announcementTitleElement } = useAnnouncementBar();
  const [popupOpen, setPopupOpen] = useState(false);
  const shippingInter = useShippingMessage();
  const isHydrationComplete = useIsHydrationComplete();

  const isLuxe = useMemo(() => {
    const shouldShowLuxe = luxe.isLuxeActive || luxe.hasLuxeInCart || luxe.shouldAddLuxeInNextATC;
    // The following condition is a fix for a hydration issue on non-US happening for this component:
    // luxe colors are displayed even if luxe data is false
    return shouldShowLuxe && gePriceDetails.CountryCode === Country.US && isHydrationComplete;
  }, [luxe, isHydrationComplete, gePriceDetails.CountryCode]);

  const { nonBannedItemQuantity } = applyCartDiscounts(cart.lines, discountInfo);
  const isQuantityPercentDiscount = DISCOUNT_TYPES.QUANTITY_PERCENT;

  if (discountInfo.type !== isQuantityPercentDiscount || isLoopReturnEnabled) {
    return null;
  }

  let shippingSentence = shippingInter;
  if (gePriceDetails?.CurrencySymbol && nonBannedItemQuantity > 0) {
    shippingSentence = dict.get("You've unlocked Free Shipping");
  }

  return (
    <div
      className={`${Styles.tierDiscountBottomBanner} ${
        hidden ? Styles.tierDiscountBottomBannerHidden : ""
      }`}
    >
      <div className={Styles.tierDiscountBottomBanner__content}>
        <div className={Styles.tierDiscountBottomBanner__content__fakeFiltersColumn} />
        <div
          className={Styles.tierDiscountBottomBanner__content__wrapper}
          onClick={() => setPopupOpen(true)}
        >
          <QuantityPercentProgressBar
            eligibleItemCount={nonBannedItemQuantity}
            componentType={ComponentType.TIER_DISCOUNT_BOTTOM_BANNER}
            announcementTitleElements={announcementTitleElement}
            isLuxe={isLuxe}
          />
          <div className={Styles.timerShipping}>
            {hasShipping && (
              <span className={`${Styles.freeShipping} ${isLuxe ? Styles.luxeActive : ""}`}>
                {shippingSentence}
              </span>
            )}
            {hasTimer && (
              <div className={`${Styles.timerDiscount}`}>
                <span className={Styles.timerTitle}>{dict.get("Sale ends in")}</span>
                &nbsp;
                <Timer />
              </div>
            )}
          </div>
        </div>
      </div>
      {hasPopup && (
        <ABPopup
          open={popupOpen}
          countryCode={gePriceDetails?.CountryCode}
          onClose={() => setPopupOpen(false)}
        />
      )}
    </div>
  );
}

export default TierDiscountBottomBanner;
