import { useContext } from "react";
import PropTypes from "prop-types";
// eslint-disable-next-line import/no-unresolved
import { useIgTestGroup } from "@intelligems/headless/gatsby";
import { LuxeContext } from "../context/LuxeProvider";
import { withALErrorBoundary } from "../helpers/ErrorBoundary/ALErrorBoundary";

import LuxeLogo from "./icons/svgs/luxe_logo.svg";
import "./solidGoldBanner/styles.scss";

function SolidGoldBanner({ tags, componentType = "", className = "" }) {
  const { luxe } = useContext(LuxeContext);
  const isOnlyExtraDiscount = (tags || []).some((r) => luxe?.extraDiscountTags?.includes(r));

  const showSolidGoldBannerTest = useIgTestGroup("1825a5df-b7cc-4564-8c08-35c72e1ede88");
  const testGroupName = showSolidGoldBannerTest?.testGroup?.name;

  if (
    (luxe &&
      !luxe.hasLuxeInCart &&
      luxe.customerSubscription &&
      luxe.customerSubscription.status !== "ACTIVE") ||
    testGroupName === "Control Group"
  ) {
    return null;
  }
  return (
    isOnlyExtraDiscount && (
      <div className={`solid_gold_container solid_gold_container--${componentType} ${className}`}>
        <LuxeLogo fill="#FFF" />

        <span className="microtext microtext--bold">
          {luxe &&
          luxe.isLuxeEnabled &&
          luxe.accountDetailsLoaded &&
          luxe.customerSubscription?.status === "ACTIVE"
            ? "Just for you: 5% off"
            : "Just for luxe: 5% off"}
        </span>
      </div>
    )
  );
}

SolidGoldBanner.propTypes = {
  tags: PropTypes.arrayOf(PropTypes.string),
  componentType: PropTypes.string,
  className: PropTypes.string,
};

export default withALErrorBoundary({
  name: "SolidGoldBanner",
  priority: "P3",
})(SolidGoldBanner);
