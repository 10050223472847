import PropTypes from "prop-types";
import { useContext } from "react";
import { differenceInDays, formatISO, parse } from "date-fns";
import { utcToZonedTime } from "date-fns-tz";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useCurrentFormatCurrency } from "../../hooks/usePrices";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import { roundNumber } from "../../context/helpers";
import { ShippingDelayContext } from "../../context/ShippingDelayProvider";
import { getShipByDate } from "../../helpers/product";

import DISCOUNT_TYPES from "../../constants/DiscountTypes";
import LuxeIconCart from "../icons/svgs/luxe-cart.svg";
import { Country } from "../../constants/Country";
import { HandleType } from "../../constants/HandleType";
import { PREORDER_TIMEZONE } from "../../constants/Common";

function getDaysUntilShipping(shipsByDate) {
  return differenceInDays(
    utcToZonedTime(
      `${formatISO(shipsByDate, { representation: "date" })}T23:59:59Z`,
      PREORDER_TIMEZONE
    ),
    new Date()
  );
}

function PriceSummary({ sideCartInfo, shippingInfo, discountInfo, countryCode, isLuxe }) {
  const { deliveryDateFormatted, isShippingEstimationLoading } = useContext(ShippingDelayContext);
  const formatCurrency = useCurrentFormatCurrency();
  const dict = useLocalizedSentenceDict();

  const siteWideDiscountItemCount = sideCartInfo.nonBannedItemQuantity;
  const hasFreeShipping =
    (shippingInfo?.freeShippingThreshold &&
      sideCartInfo.finalAmount > shippingInfo.freeShippingThreshold) ||
    shippingInfo?.freeShippingThreshold === 0;

  const preOrderItems = sideCartInfo.lines.filter((lineItem) =>
    lineItem.attributes?.find(({ key, value }) => key === "pre_order" && value === "true")
  );

  let deliveryDateToDisplay = `Estimated Delivery by: ${deliveryDateFormatted}`;

  // Show preorder date only if there is one item in the cart and that item is a preorder
  if (
    sideCartInfo.lines.filter(
      (l) =>
        ![HandleType.SUBSCRIPTION, HandleType.ORDER_PROTECTION].includes(
          l?.variant?.product?.handle
        )
    ).length === 1 &&
    preOrderItems.length === 1
  ) {
    const shipsByDateAttr =
      preOrderItems[0].attributes?.find(({ key }) => key === "_ships_by_pre_order")?.value || "";
    const shipsByDate = parse(shipsByDateAttr, "MMM d, yyyy", new Date());
    const daysUntilShipping = shipsByDate ? getDaysUntilShipping(shipsByDate) : 0;
    if (daysUntilShipping > 7) {
      deliveryDateToDisplay = `Ships by: ${getShipByDate(
        formatISO(shipsByDate, { representation: "date" }),
        "MMM do"
      )}`;
    }
  }
  if (preOrderItems.length > 1) {
    deliveryDateToDisplay = "";
  }

  const showStandardDiscountMessage =
    discountInfo.type !== DISCOUNT_TYPES.QUANTITY_PERCENT ||
    (discountInfo.type === DISCOUNT_TYPES.QUANTITY_PERCENT &&
      siteWideDiscountItemCount < discountInfo?.discounts[0][0]);

  let discountCodeText = "";

  if (discountInfo.type === DISCOUNT_TYPES.QUANTITY_PERCENT && discountInfo?.discounts.length > 0) {
    const firstTier = discountInfo.discounts[0];
    const lastTier = discountInfo.discounts[discountInfo.discounts.length - 1];

    if (siteWideDiscountItemCount < lastTier[0] && siteWideDiscountItemCount >= firstTier[0]) {
      const appliedDiscount = discountInfo.discounts.find(
        (d) => d[0] === siteWideDiscountItemCount
      )[1];
      // TODO: 5 is currently static, create + extract AL Luxe config file
      discountCodeText = `${isLuxe ? appliedDiscount + 5 : appliedDiscount}% OFF`;
    }
    if (siteWideDiscountItemCount >= lastTier[0]) {
      discountCodeText = `${lastTier[1]}% OFF`;
    }
  }

  const shippingValueUS = (
    <span>
      <span className="sidecart_shipping_price">{formatCurrency(5.99)}</span>
      <span className="sidecart_shipping_free">FREE</span>
    </span>
  );

  let shippingValue = "";
  if (hasFreeShipping) {
    shippingValue = dict.get("FREE");
  } else if (countryCode === Country.US) {
    shippingValue = shippingValueUS;
  } else {
    shippingValue = formatCurrency(shippingInfo?.shippingPrice);
  }

  return (
    <div className="sidecart_total">
      {/* Subtotal */}
      <p className="sidecart_total_content">
        <span>{dict.get("Subtotal")}</span>
        <span>{formatCurrency(sideCartInfo.fullAmount)}</span>
      </p>

      {/* AL Luxe */}
      {isLuxe && sideCartInfo.discountLuxe > 0 && (
        <p className="sidecart_total_content">
          <span className="sidecart_total_content--color mr--5">
            <LuxeIconCart style={{ marginRight: "3px" }} />
            <b>Extra 5% OFF</b>
          </span>
          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(sideCartInfo.discountLuxe))}`}
          </span>
        </p>
      )}

      {/* Buy More, Save More */}
      {sideCartInfo.fullAmount !== sideCartInfo.finalAmount &&
        sideCartInfo.discountTieredOffer > 0 && (
          <p className="sidecart_total_content">
            <span>
              <span className="sidecart_total_content--color mr--5">
                {showStandardDiscountMessage
                  ? dict.get("Discount")
                  : dict.get("Buy More, Save More:")}
              </span>
              <span className="sidecart_total_content_title_discount">{discountCodeText}</span>
            </span>

            <span className="sidecart_total_content_value_color">
              {`-${formatCurrency(roundNumber(sideCartInfo.discountTieredOffer))}`}
            </span>
          </p>
        )}

      {sideCartInfo.discountCompareAtPrice > 0 && (
        <p className="sidecart_total_content">
          <span>
            <span className="sidecart_total_content--color mr--5">
              {dict.get("Last Chance Discount:")}
            </span>
          </span>

          <span className="sidecart_total_content_value_color">
            {`-${formatCurrency(roundNumber(sideCartInfo.discountCompareAtPrice))}`}
          </span>
        </p>
      )}

      <div className="sidecart_total_content">
        <div className="sidecart_total_content_shipping">
          <p className="sidecart_total_content_shipping_title">{dict.get("Shipping")}</p>
          {deliveryDateToDisplay && !isShippingEstimationLoading && (
            <p className="sidecart_total_content_shipping_dates microtext">
              {deliveryDateToDisplay}
            </p>
          )}
        </div>
        <span className={`${hasFreeShipping ? "sidecart_total_content_value_color" : ""}`}>
          {shippingValue}
        </span>
      </div>
    </div>
  );
}

PriceSummary.propTypes = {
  sideCartInfo: PropTypes.shape({
    lines: PropTypes.array,
    fullAmount: PropTypes.number,
    finalAmount: PropTypes.number,
    nonBannedItemQuantity: PropTypes.number,
    discountLuxe: PropTypes.number,
    discountTieredOffer: PropTypes.number,
    discountCompareAtPrice: PropTypes.number,
  }).isRequired,
  shippingInfo: PropTypes.shape({
    freeShippingThreshold: PropTypes.number,
    shippingPrice: PropTypes.number,
  }).isRequired,
  discountInfo: PropTypes.shape({
    code: PropTypes.string,
    type: PropTypes.string,
    discounts: PropTypes.array,
  }),
  countryCode: PropTypes.string.isRequired,
  isLuxe: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "PriceSummary",
  priority: "P1",
})(PriceSummary);
