import { useContext } from "react";
import PropTypes from "prop-types";

import { LoopReturnContext } from "../../context/LoopReturnProvider";
import { gaCustomEvent, decodeProductId } from "../../context/helpers";
import { useTracking } from "../../context/Tracking";
import { usePriceInfo } from "../../hooks/usePrices";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { ComponentType } from "../../constants/ComponentType";

import ALShopifyImage from "../al_components/ALShopifyImage";
import { Link } from "../al_components/ALLink";
import PriceContainer from "../PriceContainer";

import * as browseProductsStyles from "./browseProductsProduct/browseProductsStyles.module.scss";

function BrowseProductsProduct({
  handle,
  product,
  sectionTitle,
  recommendationId,
  componentType,
  handleProductClick = () => {},
}) {
  const { isLoopReturnEnabled } = useContext(LoopReturnContext);
  const { trackRecommendedProduct } = useTracking();

  const [{ data: prices, isInitialLoading: pricesLoading }] = usePriceInfo({
    handles: [handle],
    tags: { [handle]: product?.tags },
    shouldFetchPrice: true,
  });

  const onProductClick = (productTitle) => {
    const label = `${sectionTitle} - ${productTitle}`;
    gaCustomEvent("Recommended Product", "Click", label);
    gaCustomEvent(`Recommended Product - ${sectionTitle}`, "click", productTitle);
    trackRecommendedProduct("click", sectionTitle, {
      productTitle: product.node.title,
      productCategory: product.node.category,
      productId: decodeProductId(product.node.id),
      recommendationId,
    });

    handleProductClick();
  };

  function getLinkTo() {
    const titleKey = sectionTitle.toLowerCase().split(" ").join("-");
    return `/products/${handle}/?origin_content=recommended-${titleKey}`;
  }

  const image = product?.node?.images?.[0];
  const imageWidth = useResponsiveWithHydrationFix(window.innerWidth, window.innerWidth / 4);

  // Do not display engravable products to customers placing a replacement order
  if (isLoopReturnEnabled && product?.contentful?.node?.engravable?.isEngravable) {
    return null;
  }

  if (handle && product) {
    return (
      <div className={componentType === ComponentType.PDP ? "" : "browse_product_wrapper"}>
        <Link
          className={`${browseProductsStyles.browse_product_link} browse_product_link`}
          to={getLinkTo()}
          onClick={() => onProductClick(product.node.title)}
        >
          <span className={browseProductsStyles.srOnly}>{product.node.title.split("-")[1]}</span>
          <div>
            <div
              className={`${browseProductsStyles.browse_product_image} browse_product_image`}
              aria-hidden="true"
              focusable="false"
            >
              {image ? (
                <ALShopifyImage
                  width={imageWidth}
                  height={imageWidth}
                  url={image.url}
                  alt={image.altText}
                />
              ) : null}
            </div>
            <div
              className={browseProductsStyles.browse_product_content}
              aria-hidden="true"
              focusable="false"
            >
              <h3
                className={`${browseProductsStyles.browse_product_content_title} browse_product_content_title`}
              >
                {product.node.title.split(" - ")[1]}
              </h3>
              <PriceContainer
                productId={product.node.id}
                componentType={ComponentType.CP}
                prices={prices}
                pricesLoading={pricesLoading}
                tags={product.node.tags}
              />
            </div>
          </div>
        </Link>
      </div>
    );
  }
  return null;
}

BrowseProductsProduct.propTypes = {
  handle: PropTypes.string,
  product: PropTypes.object,
  sectionTitle: PropTypes.string,
  recommendationId: PropTypes.string,
  componentType: PropTypes.string,
  handleProductClick: PropTypes.func,
};

export default BrowseProductsProduct;
