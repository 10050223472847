import { useContext, useMemo } from "react";
import PropTypes from "prop-types";
import SwiperCore, { Autoplay, Pagination, Navigation, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { useAnnouncementBar } from "../../hooks/useAnnouncementBar";
import { useShippingMessage } from "../../hooks/useShippingInfo";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";
import useIsHydrationComplete from "../../hooks/useIsHydrationComplete";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";

import { DiscountContext } from "../../context/DiscountProvider";
import { CartContext } from "../../context/CartProvider";
import { LuxeContext } from "../../context/LuxeProvider";

import DISCOUNT_TYPES from "../../constants/DiscountTypes";

import { applyCartDiscounts } from "../cartDiscounts";

import { ALLoading } from "../ALComponents";
import QuantityPercentProgressBar from "../al_components/QuantityPercentProgressBar";
import Timer from "../Timer";

import { ComponentType } from "../../constants/ComponentType";
import { Country } from "../../constants/Country";
import COLORS from "../../constants/Colors";

import "swiper/swiper.min.css";
import "swiper/swiper-bundle.min.css";
import * as styles from "./AnnouncementBar.module.scss";

SwiperCore.use([Autoplay, Pagination, Navigation, A11y]);

function AnnouncementBarLayout({
  textColor,
  hasPopup,
  gePriceDetails,
  announcementTitleElements,
  setPopupOpen,
  hidden,
}) {
  const { announcementSubtitle, disclaimer, backgroundColor, hasShipping, hasTimer, isRotating } =
    useAnnouncementBar();

  const { discountInfo } = useContext(DiscountContext);
  const { cart } = useContext(CartContext);
  const { luxe } = useContext(LuxeContext);

  const sideCartInfo = applyCartDiscounts(cart.lines, discountInfo);
  const dict = useLocalizedSentenceDict();
  const shippingInter = useShippingMessage();
  const isHydrationComplete = useIsHydrationComplete();
  const countryCode = gePriceDetails?.CountryCode;

  const isLuxe = useMemo(() => {
    const shouldShowLuxe = luxe.isLuxeActive || luxe.hasLuxeInCart || luxe.shouldAddLuxeInNextATC;
    // The following condition is a fix for a hydration issue on non-US happening for this component:
    // luxe colors are displayed even if luxe data is false
    return shouldShowLuxe && countryCode === Country.US && isHydrationComplete;
  }, [luxe, isHydrationComplete, countryCode]);

  const isQuantityPercentDiscount = discountInfo?.type === DISCOUNT_TYPES.QUANTITY_PERCENT;
  let shippingSentence = shippingInter;

  // Default wording
  if (countryCode === Country.US && sideCartInfo?.nonBannedItemQuantity > 0) {
    shippingSentence = dict.get("You've unlocked Free Shipping");
  }

  const staticLayout = isQuantityPercentDiscount ? (
    <div className={`${styles.content} ${styles.staticContent} ${isLuxe ? styles.luxeActive : ""}`}>
      <p className={`${styles.shippingSentence} ${styles.shippingSentenceDiscount}`}>
        {shippingSentence}
      </p>
      <div className={styles.discountContainer}>
        <div className={styles.progressBarDiscount}>
          <QuantityPercentProgressBar
            eligibleItemCount={sideCartInfo.nonBannedItemQuantity}
            componentType={ComponentType.ANNOUNCEMENT_BAR}
            announcementTitleElements={announcementTitleElements}
            isLuxe={isLuxe}
          />
        </div>
      </div>
      {hasTimer && (
        <div className={`${styles.staticTimerDiscount} microtext`}>
          <span className={styles.timerTitle}>{dict.get("Sale ends in")}</span>
          &nbsp;
          <Timer />
        </div>
      )}
    </div>
  ) : (
    <div className={`${styles.content} ${styles.staticContent} ${isLuxe ? styles.luxeActive : ""}`}>
      <p className={styles.shippingSentence}>
        {hasShipping && gePriceDetails?.CurrencySymbol && shippingSentence}
      </p>
      <span className={styles.titleContainer}>
        {announcementTitleElements && (
          <p className={styles.announcementTitle}>{announcementTitleElements}</p>
        )}
        {announcementSubtitle !== "null" && (
          <p className={styles.announcementSubtitle}>{announcementSubtitle}</p>
        )}
      </span>
      {hasTimer && (
        <div className={styles.timer}>
          <span className={styles.timerTitle}>{dict.get("Sale ends in")}</span>
          &nbsp;
          <Timer />
        </div>
      )}
    </div>
  );

  const sliderLayout = (
    <Swiper
      spaceBetween={80}
      slidesPerView={1}
      centeredSlides
      speed={750}
      loop
      enabled={isRotating}
      autoplay={{
        delay: 4000,
        disableOnInteraction: true,
        pauseOnMouseEnter: false,
      }}
      className={`${styles.swiperContainer} ${isLuxe ? styles.luxeActive : ""}`}
    >
      {/* first slide */}
      <SwiperSlide className={styles.swiperSlide}>
        {isQuantityPercentDiscount ? (
          <div className={styles.firstSlideDiscount}>
            <QuantityPercentProgressBar
              eligibleItemCount={sideCartInfo.nonBannedItemQuantity}
              componentType={ComponentType.ANNOUNCEMENT_BAR}
              announcementTitleElements={announcementTitleElements}
              isLuxe={isLuxe}
            />
            <div className={styles.swiperTimerShipping}>
              <span className={styles.swiperFreeShipping}>
                {hasShipping && gePriceDetails?.CurrencySymbol && shippingInter}
              </span>
              {hasTimer && (
                <div className={`${styles.swiperTimerDiscount} ${styles.discountProgress}`}>
                  <Timer />
                </div>
              )}
            </div>
          </div>
        ) : (
          <div className={`${styles.firstSlide} ${styles.slideContainer}`}>
            <div>
              {announcementTitleElements !== "null" && (
                <p className={styles.announcementTitle}>{announcementTitleElements}</p>
              )}
              {announcementSubtitle !== "null" && (
                <p className={styles.announcementSubtitle}>{announcementSubtitle}</p>
              )}
            </div>
            {hasTimer && (
              <div className={styles.timer}>
                <Timer />
              </div>
            )}
          </div>
        )}
      </SwiperSlide>
      {/* second slide */}
      <SwiperSlide className={styles.swiperSlide}>
        <div
          className={`${styles.secondSlide} ${styles.slideContainer} ${
            isQuantityPercentDiscount ? styles.secondSlideContainerDiscount : ""
          }`}
        >
          <div>
            <p className={styles.shippingSentence}>
              {hasShipping && gePriceDetails?.CurrencySymbol && shippingSentence}
            </p>
            {disclaimer !== "null" && (
              <p className={`${styles.disclaimer} subtext`}>{disclaimer}</p>
            )}
          </div>
          {hasTimer && (
            <div
              className={`${styles.timer} ${
                isQuantityPercentDiscount ? styles.timerSecondSlideDiscount : ""
              }`}
            >
              <Timer />
            </div>
          )}
        </div>
      </SwiperSlide>
    </Swiper>
  );

  const displaySliderLayout = disclaimer !== "null" || hasShipping;

  const layout = useResponsiveWithHydrationFix(
    displaySliderLayout ? sliderLayout : staticLayout,
    staticLayout
  );

  return (
    <div
      className={`${styles.container} ${hidden ? styles.containerHidden : ""}`}
      style={{
        backgroundColor: isQuantityPercentDiscount ? COLORS.OAT : backgroundColor || COLORS.POPPY,
        color: textColor,
        cursor: hasPopup ? "pointer" : "default",
      }}
      role="button"
      tabIndex={0}
      onKeyDown={(evt) => (evt.key === "Enter" ? hasPopup && setPopupOpen(true) : null)}
      onClick={() => hasPopup && setPopupOpen(true)}
    >
      {countryCode ? (
        layout
      ) : (
        <span className={styles.spinnerContainer}>
          <ALLoading />
        </span>
      )}
    </div>
  );
}

AnnouncementBarLayout.propTypes = {
  textColor: PropTypes.string,
  hasPopup: PropTypes.bool,
  gePriceDetails: PropTypes.object,
  announcementTitleElements: PropTypes.string,
  setPopupOpen: PropTypes.func,
  hidden: PropTypes.bool,
};

export default withALErrorBoundary({
  name: "AnnouncementBarLayout",
  priority: "P1",
})(AnnouncementBarLayout);
