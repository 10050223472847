import { PropTypes } from "prop-types";
import { Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import useResponsiveWithHydrationFix from "../../../../../hooks/useResponsiveWithHydrationFix";

import * as Styles from "./searchLinks/styles.module.scss";

function SearchLinksElement({ image }) {
  const { mobileImage, desktopImage, altText, link } = image;
  const imageSource = useResponsiveWithHydrationFix(
    mobileImage.gatsbyImageData,
    desktopImage.gatsbyImageData
  );
  return (
    <Link to={link.link.link}>
      <GatsbyImage image={imageSource} alt={altText} />
      <p className="subtext">{link.title}</p>
    </Link>
  );
}

function SearchLinks({ currentRefinement, links }) {
  if (currentRefinement || !links?.length) {
    return null;
  }
  return (
    <div className={Styles.linksCarousel} data-testid="search-links">
      {links.map((image) => (
        <SearchLinksElement key={image.link.title} image={image} />
      ))}
    </div>
  );
}

SearchLinks.propTypes = {
  currentRefinement: PropTypes.string,
  links: PropTypes.arrayOf(
    PropTypes.shape({
      mobileImage: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
      desktopImage: PropTypes.shape({
        gatsbyImageData: PropTypes.object,
      }),
      altText: PropTypes.string,
      link: PropTypes.shape({
        title: PropTypes.string,
        link: PropTypes.shape({ link: PropTypes.string }),
      }),
    })
  ),
};

export default SearchLinks;
