import { useMemo } from "react";
import { connectSearchBox } from "react-instantsearch-dom";
import { PropTypes } from "prop-types";

import useResponsiveWithHydrationFix from "../../../../hooks/useResponsiveWithHydrationFix";

import IconSearch from "../../../icons/svgs/search.svg";
import IconClose from "../../../icons/svgs/close-round-lg.svg";
import IconClear from "../../../icons/svgs/close.svg";

import * as Styles from "./customSearchBox/styles.module.scss";

function getValueAutoCompleteFromHits(currentRefinement, hits = []) {
  // Get first full word based on hits results
  if (!currentRefinement || !hits.length) return "";

  const fullTitle = hits[0]?.contentful?.title?.toLowerCase() || "";
  const currentSearch = currentRefinement.toLowerCase();
  const predictedWord = fullTitle.split(" ").find((word) => word.startsWith(currentSearch)) || "";
  if (!predictedWord || predictedWord === currentSearch) return "";
  const completedWord = currentRefinement + predictedWord.substring(currentSearch.length);
  return completedWord;
}

function AutoCompleteInput({ value, onChange, valueAutocomplete = "" }) {
  return (
    <div className={Styles.searchBox__wrapper__autocomplete}>
      {value && valueAutocomplete && (
        <div className={`${Styles.searchBox__autocomplete} text`}>
          <div className={Styles.searchBox__autocomplete__current}>{value}</div>
          <div className={Styles.searchBox__autocomplete__prediction}>
            {valueAutocomplete.split(value)?.[1] || ""}
          </div>
        </div>
      )}
      <input
        value={value}
        onChange={(e) => onChange(e.target.value)}
        onKeyDown={(e) => e.key === "Tab" && valueAutocomplete && onChange(valueAutocomplete)}
        type="text"
        className={`${Styles.searchBox__input}`}
        autoFocus
      />
    </div>
  );
}

function CustomSearchBox({ currentRefinement, refine, closeSearch, hits }) {
  const valueAutocomplete = useMemo(
    () => getValueAutoCompleteFromHits(currentRefinement, hits),
    [currentRefinement, hits]
  );

  const closeButton = useResponsiveWithHydrationFix(
    null,
    <button type="button" className={Styles.searchClose} onClick={closeSearch}>
      <IconClose fill="none" stroke="#2D2927" width="40px" />
    </button>
  );

  return (
    <>
      <div className={Styles.wrapper}>
        <div className={Styles.searchBox}>
          <IconSearch
            width="17"
            height="17"
            fill="none"
            stroke="#898786"
            className={Styles.searchIcon}
          />
          <AutoCompleteInput
            value={currentRefinement}
            onChange={refine}
            valueAutocomplete={valueAutocomplete}
          />
          {currentRefinement !== "" ? (
            <button
              type="button"
              className={`${Styles.searchBox__inputRemove} al_p`}
              onClick={() => refine("")}
            >
              <IconClear width="25px" fill="#2D2927" />
            </button>
          ) : null}
        </div>
        {closeButton}
      </div>

      {valueAutocomplete && (
        <div className={`${Styles.suggestion} text`}>
          Are you looking for{" "}
          <button className="text" type="button" onClick={() => refine(valueAutocomplete)}>
            <b>
              <u>{valueAutocomplete}</u>
            </b>
          </button>
          ?
        </div>
      )}
    </>
  );
}

CustomSearchBox.propTypes = {
  currentRefinement: PropTypes.string,
  refine: PropTypes.func,
  closeSearch: PropTypes.func,
};

export default connectSearchBox(CustomSearchBox);
