import { useContext } from "react";
import PropTypes from "prop-types";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";
import { DiscountContext } from "../../context/DiscountProvider";

import useUpSellProducts from "../../hooks/useUpSellProducts";
import { useLocalizedProductsWithDiscount } from "../../hooks/useLocalizedCartInfo";

import UpSells from "./UpSells";

function UpSellsRecommendation({ lines = [] }) {
  const { discountInfo } = useContext(DiscountContext);

  const {
    data: recommendedProducts,
    isLoading,
    id: recommendationId,
  } = useUpSellProducts(lines, discountInfo);
  const products = useLocalizedProductsWithDiscount(recommendedProducts);

  return (
    <UpSells
      title="You may also like"
      products={products}
      isLoading={isLoading}
      recommendationId={recommendationId}
      slidesPerView={1.3}
    />
  );
}

UpSellsRecommendation.propTypes = {
  lines: PropTypes.array,
};

export default withALErrorBoundary({
  name: "UpSellsRecommendation",
  priority: "P3",
})(UpSellsRecommendation);
