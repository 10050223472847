import { PropTypes } from "prop-types";
import { connectSearchBox } from "react-instantsearch-dom";

import { useLocalizedSentenceDict } from "../../../../hooks/useSentenceDict";

import Categories from "./Categories";
import SearchCards from "./topSearches/SearchCards";
import SearchLinks from "./topSearches/SearchLinks";

import * as Styles from "./topSearches/styles.module.scss";

function TopSearches({ refine, topCategories, cards, nbHits, currentRefinement, links }) {
  const dict = useLocalizedSentenceDict();

  if (currentRefinement && nbHits > 0) {
    return null;
  }

  return (
    <div className={Styles.topSearches}>
      <div>
        <h3 className={`h3 h3--bold h3--uc ${Styles.topSearches__title}`}>
          {nbHits === 0 ? dict.get("No matches found") : dict.get("Top Searches")}
        </h3>
        {nbHits === 0 && (
          <p className="subtext">
            {dict.get(
              "Try another search or maybe we can interest you with these popular search terms:"
            )}
          </p>
        )}
        <Categories refine={refine} categories={topCategories} />
        <SearchLinks currentRefinement={currentRefinement} links={links} />
      </div>
      {nbHits > 0 && cards && cards.length > 0 && <SearchCards cards={cards} />}
    </div>
  );
}

TopSearches.propTypes = {
  refine: PropTypes.func,
  topCategories: PropTypes.arrayOf(PropTypes.string),
  cards: PropTypes.arrayOf(PropTypes.shape({})),
  nbHits: PropTypes.number,
  currentRefinement: PropTypes.string,
  links: PropTypes.arrayOf(PropTypes.shape({})),
};

export default connectSearchBox(TopSearches);
