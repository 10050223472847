import { Link } from "../al_components/ALLink";
import ImagesContainer from "./ImagesContainer";
import { gaCustomEvent } from "../../context/helpers";
import { useLocalizedSentenceDict } from "../../hooks/useSentenceDict";

import IconArrowRoundSm from "../icons/svgs/arrow-circle-sm.svg";

import * as Styles from "./completeTheLook.module.scss";

function CompleteTheLook({ products, productTitle, handle }) {
  const dict = useLocalizedSentenceDict();
  const onProductClick = (title) => {
    gaCustomEvent(`Complete the look - ${productTitle}`, "Click", title);
  };

  return (
    <div className={Styles.container}>
      <h2 className={Styles.container_title}>{dict.get("Recommended product pairings")}</h2>
      <div className={Styles.products_container}>
        {products.map((product, i) => {
          const shopifyProduct = product.shopify;
          const productsTitle = shopifyProduct?.title.split(" - ")[1] || shopifyProduct?.title;
          return (
            <Link
              className={Styles.product_container}
              to={`/products/${product.handle}?origin_content=complete-the-look-${handle}`}
              key={i}
              onClick={() => onProductClick(shopifyProduct.title)}
            >
              {shopifyProduct?.images?.length > 0 ? (
                <ImagesContainer images={shopifyProduct.images} />
              ) : null}
              <div className={Styles.bottom}>
                <p className="subtext">{productsTitle}</p>
                <IconArrowRoundSm width="24" stroke="#2D2927" className={Styles.bottom_arrow} />
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}

export default CompleteTheLook;
