import { PropTypes } from "prop-types";
import { useEffect, useMemo } from "react";
import { useStaticQuery, graphql } from "gatsby";

import { connectStateResults } from "react-instantsearch-dom";

import { useLocalizedSentenceDict } from "../../../hooks/useSentenceDict";
import useResponsiveWithHydrationFix from "../../../hooks/useResponsiveWithHydrationFix";
import { useDebounce } from "../../../hooks/useDebounce";
import { useCurrentLang } from "../../../hooks/useLocalizedCartInfo";
import { useTracking } from "../../../context/Tracking";

import { ComponentType } from "../../../constants/ComponentType";

import { ALButton } from "../../ALComponents";
import ALLogo from "../../al_components/ALLogo";
import BrowseProducts from "../../blocks/BrowseProducts";
import SortsCollection from "../../CollectionPage/SortsCollection";
import CustomHitCount from "./customSearchUI/CustomHitCount";
import CustomInfiniteHits from "./customSearchUI/CustomInfiniteHits";
import CustomSearchBox from "./customSearchUI/CustomSearchBox";
import TopSearches from "./customSearchUI/TopSearches";
import IconClose from "../../icons/svgs/close-round.svg";

import * as Styles from "./customSearchUI/styles.module.scss";

const ORDER_NUMBER_REGEX = /18\d*6/;

function CustomSearchUI({
  searchState,
  searchResults,
  sortBy,
  onChangeSortBy,
  onCardClick,
  indexName,
  closeSearch,
  allSearchResults,
}) {
  const hits = allSearchResults?.hits || [];
  const lang = useCurrentLang();

  const contentfulData = useStaticQuery(graphql`
    query searchLandingPage {
      allContentfulSearchLandingPage {
        nodes {
          node_locale
          title
          categories
          links {
            altText
            desktopImage {
              gatsbyImageData(width: 120, height: 120)
              file {
                url
              }
            }
            mobileImage {
              gatsbyImageData(width: 90, height: 90)
              file {
                url
              }
            }
            link {
              title
              link {
                link
              }
            }
          }
          cards {
            title
            showArrow
            image {
              altText
              link {
                title
                link {
                  link
                }
              }
              desktopImage {
                file {
                  url
                }
              }
              mobileImage {
                file {
                  url
                }
              }
            }
          }
          productsCarousel {
            ... on ContentfulHomePageBrowseProducts {
              __typename
              contentful_id
              title
              collectionHandle
            }
          }
          productsCarouselFailedSearches {
            ... on ContentfulHomePageBrowseProducts {
              __typename
              contentful_id
              title
              collectionHandle
            }
          }
        }
      }
    }
  `);

  const searchData = useMemo(() => {
    let data = null;
    if (
      contentfulData.allContentfulSearchLandingPage.nodes &&
      contentfulData.allContentfulSearchLandingPage.nodes.length > 0
    ) {
      const defaultEntry = contentfulData.allContentfulSearchLandingPage.nodes.filter(
        (card) => card.node_locale === "en-US"
      );
      const localeSpecificEntry = contentfulData.allContentfulSearchLandingPage.nodes.filter(
        (card) => card.node_locale === lang
      );
      data = localeSpecificEntry ?? defaultEntry;
    }
    return data.find((entry) => !entry.title.includes("null"));
  }, [contentfulData.allContentfulSearchLandingPage.nodes, lang]);

  const separator = useResponsiveWithHydrationFix(
    <hr className={Styles.customSearchHeader__separator} />,
    null
  );

  const header = useResponsiveWithHydrationFix(
    <div className={Styles.customSearchHeader}>
      <ALLogo className={Styles.customSearchHeader__logo} />
      <button type="button" className={Styles.searchClose} onClick={closeSearch}>
        <IconClose fill="none" stroke="#2D2927" width="30px" />
      </button>
    </div>,
    null
  );
  const dict = useLocalizedSentenceDict();
  const debouncedQuery = useDebounce(searchState?.query, 500);
  const { trackSearch } = useTracking();

  useEffect(() => {
    if (debouncedQuery) {
      if (searchResults) {
        trackSearch({ query: debouncedQuery, searchResults: searchResults.hits });
      }
    }
  }, [debouncedQuery, trackSearch]);

  // close search modal on escape key press
  useEffect(() => {
    const close = (e) => {
      if (e.keyCode === 27) {
        closeSearch();
      }
    };
    window.addEventListener("keydown", close);
    return () => window.removeEventListener("keydown", close);
  }, [closeSearch]);

  const isOrderFormat = ORDER_NUMBER_REGEX.test(searchState?.query);

  let orderNumber;
  if (isOrderFormat) {
    orderNumber = searchState.query.match(ORDER_NUMBER_REGEX)[0];
  }

  return (
    <div className={Styles.customSearch}>
      {header}
      {separator}
      <div className={Styles.customSearchContent}>
        <CustomSearchBox closeSearch={closeSearch} hits={hits} />
        {!isOrderFormat && (
          <TopSearches
            topCategories={searchData.categories}
            cards={searchData.cards}
            nbHits={searchResults?.nbHits}
            links={searchData.links}
          />
        )}
        {searchState?.query &&
          searchResults?.nbHits === 0 &&
          searchData.productsCarouselFailedSearches && (
            <BrowseProducts
              content={searchData.productsCarouselFailedSearches}
              componentType={ComponentType.SEARCH}
            />
          )}
        {!searchState?.query && searchData.productsCarousel && (
          <BrowseProducts
            content={searchData.productsCarousel}
            componentType={ComponentType.SEARCH}
          />
        )}
        {isOrderFormat && (
          <div key="orderFormatSection">
            <p className={Styles.customSearchTrackingTitle}>
              Looking for an order? Use the link below
            </p>
            <ALButton
              size="medium"
              type="button"
              fullWidth
              className={Styles.customSearchTrackingButton}
              onClick={() => {
                window.location.href = `/track-my-order/?orderNo=${orderNumber}`;
              }}
            >
              Track My Order
            </ALButton>
            <BrowseProducts
              content={searchData.productsCarousel}
              componentType={ComponentType.SEARCH}
            />
          </div>
        )}
        {searchResults?.nbHits > 0 && searchState?.query && (
          <div className={Styles.customSearchContent__products}>
            <div className={`subtext ${Styles.customSearchContent__productsCount}`}>
              <CustomHitCount /> {dict.get("products")}
            </div>
            <SortsCollection
              className={Styles.customSearchContent__productsSort}
              onSort={(s) => typeof onChangeSortBy === "function" && onChangeSortBy(s)}
              sortingType={sortBy}
            />
          </div>
        )}
      </div>

      {/* Search results */}
      {searchState?.query && (
        <CustomInfiniteHits
          onCardClick={onCardClick}
          indexName={indexName}
          queryId={searchResults?.queryID}
        />
      )}
    </div>
  );
}

CustomSearchUI.propTypes = {
  searchState: PropTypes.object,
  searchResults: PropTypes.object,
  sortBy: PropTypes.string,
  onChangeSortBy: PropTypes.func,
  onCardClick: PropTypes.func,
  indexName: PropTypes.string,
  closeSearch: PropTypes.func,
  allSearchResults: PropTypes.object,
};

export default connectStateResults(CustomSearchUI);
