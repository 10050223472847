import { useState } from "react";
import { ALSkeleton } from "../ALComponents";
import * as Styles from "./imagesContainer.module.scss";

function ImagesContainer({ images }) {
  const [isImageLoaded, updateIsImageLoaded] = useState(false);

  return (
    <div className={Styles.images}>
      {!isImageLoaded && <ALSkeleton />}
      <div className={Styles.image}>
        <img
          onLoad={() => updateIsImageLoaded(true)}
          src={images[0]?.url}
          alt={images[0]?.altText}
        />
      </div>
      {images[1] && (
        <div className={Styles.image_hover}>
          <img src={images[1]?.url} alt={images[1]?.altText} />
        </div>
      )}
    </div>
  );
}

export default ImagesContainer;
