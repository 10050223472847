import { useState, useEffect } from "react";
import Vimeo from "@u-wave/react-vimeo";
import PropTypes from "prop-types";
import ALSkeleton from "./ALSkeleton";
import useResponsiveWithHydrationFix from "../../hooks/useResponsiveWithHydrationFix";
import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

function ALVideo({ videoId, customClasses, previewOnly }) {
  const [videoThumbnail, setVideoThumbnail] = useState(null);
  const [isVideoLoaded, updateIsVideoLoaded] = useState(false);
  const [hasError, setHasError] = useState(false);

  useEffect(() => {
    if (videoId && videoId !== "null") {
      const getThumbnail = async () => {
        const data = await fetch(`https://vimeo.com/api/v2/video/${videoId}.json`)
          .then((response) => response.json())
          .catch((e) => {
            console.error(e);
            return null;
          });
        setVideoThumbnail(data?.[0]?.thumbnail_large || null);
      };
      getThumbnail();
    }
  }, [videoId]);

  const onVideoLoaded = () => {
    setVideoThumbnail(null);
    updateIsVideoLoaded(true);
  };

  const video = (
    <Vimeo
      className={customClasses?.video}
      video={videoId}
      muted
      loop
      background
      autoplay
      dnt
      responsive
      onPlay={onVideoLoaded}
      onError={() => {
        setHasError(true);
      }}
    />
  );

  const videoContainer = useResponsiveWithHydrationFix(
    video,
    <div className={`video_vimeo_content ${customClasses?.vimeo_container || ""}`}>{video}</div>
  );

  return (
    <div className={`video_vimeo_container ${customClasses?.container || ""}`}>
      {videoThumbnail && (!isVideoLoaded || previewOnly || hasError) ? (
        <img
          className={customClasses?.image_thumbnail || ""}
          src={videoThumbnail}
          alt="video thumbnail"
          style={isVideoLoaded ? { opacity: 0, display: "none" } : { opacity: 1 }}
        />
      ) : (
        (!isVideoLoaded || previewOnly) && (
          <div className={`${customClasses?.skeleton_container || ""}`} style={{ width: "100%" }}>
            <div className={`${customClasses?.skeleton_image || ""}`}>
              <ALSkeleton />
            </div>
          </div>
        )
      )}
      <div className={customClasses?.video_container} />

      {!previewOnly && videoContainer}
    </div>
  );
}

ALVideo.propTypes = {
  videoId: PropTypes.string.isRequired,
  customClasses: PropTypes.shape({
    container: PropTypes.string,
    vimeo_container: PropTypes.string,
    image_thumbnail: PropTypes.string,
    video_container: PropTypes.string,
    video: PropTypes.string,
    skeleton_container: PropTypes.string,
    skeleton_image: PropTypes.string,
  }),
  previewOnly: PropTypes.bool,
};

export default withALErrorBoundary({ name: "ALVideo", priority: "P4" })(ALVideo);
