import { useContext, useMemo } from "react";
import { useAuth } from "./AuthProvider";
import { useCurrentCountryCode } from "../hooks/usePrices";
import { isBrowser, gaCustomEvent, getSessionUserId, getCookie, hashEmail } from "./helpers";
import { getShopifyStoreByCountry } from "../../locale-shopifies";
import { GeoContext } from "./GeoProvider";

import {
  gtmSendEvent,
  gtmSendAddToCart,
  gtmSendAddToWishlist,
  gtmSendRemoveFromCart,
  gtmSendProductClick,
  gtmSendViewCollectionPage,
  gtmSendViewProductPage,
  gtmSendBundleUpdate,
  gtmSendAddBundleToCart,
  gtmSendViewCart,
  gtmSendSearchEvent,
  gtmSendSignUp,
} from "./tracking/trackingGTM";

import {
  expSendViewProductPage,
  expSendAddToWishlist,
  expSendRemoveFromWishlist,
  expSendAccountError,
  expSendUserSignup,
  expSendUserLogin,
  expSendDropHintOpenPopup,
  expSendDropHintClosePopup,
  expSendDropHintIconClick,
  expSendBackToTopClick,
  expSendSideCartOpen,
  expSendMenuClick,
  expSendCollectionFilterClick,
  expSendCollectionSortClick,
  expSendFloatingAddToCart,
  expTrackAddPreBundledProductToCart,
  expTrackQuickViewClick,
  expTrackViewProductDetailsOnByob,
  expTrackCollectionCards,
  expTrackSubscriptionCancel,
  expTrackSubscriptionSkip,
  expTrackSubscriptionPause,
  expTrackSubscriptionReactivate,
  expTrackAddToCart,
  expTrackRecommendedProduct,
  expTrackBundleUpdate,
  expTrackAddBundleToCart,
  expTrackBundleItems,
  expTrackClickAlternative,
  expOptionalProductsDrawerOpen,
  expOptionalProductsDrawerAdd,
  expOptionalProductsDrawerRemove,
  expOptionalProductsDrawerEdit,
  expOptionalProductsDrawerConfirmation,
  expTrackCountryUpdate,
  expTrackRemoveFromCart,
} from "./tracking/trackingExponea";

import { juSendAddToCart, juViewSideCart } from "./tracking/trackingJustUno";

import {
  fbSendEvent,
  fbSendAddToCart,
  fbSendViewProductPage,
  fbSendPageView,
  fbSendAddToWishlist,
  fbSendUserSignup,
} from "./tracking/trackingFacebook";

import {
  pinSendViewProductPage,
  pinSendAddToCart,
  pinSendPageVisit,
  pinSendViewCategory,
} from "./tracking/trackingPinterest";

import {
  algoliaConvertedObjectIDsAfterSearch,
  algoliaProductClickAfterSearch,
  algoliaProductAddedToCart,
} from "./tracking/trackingAlgolia";

import {
  elevarSendViewCart,
  elevarSendAddToCart,
  elevarSendRemoveFromCart,
  elevarSendViewPLP,
  elevarSendSearch,
  elevarSendLogin,
  elevarSendSignUp,
  elevarSendPageView,
  elevarViewProduct,
} from "./tracking/trackingElevar";

import {
  bingSendAddToCart,
  bingViewCollection,
  bingViewProduct,
  bingViewCart,
} from "./tracking/trackingBing";

import {
  retentionSendPageView,
  retentionSendAddToCart,
  retentionViewProduct,
  retentionViewCollection,
} from "./tracking/trackingRetention";

import {
  blotoutSendPageView,
  blotoutSendAddToCart,
  blotoutViewProduct,
  blotoutQuickViewProduct,
  blotoutSearch,
} from "./tracking/trackingBlotout";

import { ComponentType } from "../constants/ComponentType";

export function sendUserSignIn(email) {
  gtmSendEvent("user_signin", { user_email: hashEmail(email) });
}

function hasAddressRequiredFields(address) {
  // These fields are required when sending the address
  const requiredFields = ["first_name", "last_name", "postal_code", "country"];
  const addressKeys = Object.keys(address);
  const hasRequiredFields = requiredFields.every((field) => addressKeys.includes(field));

  return hasRequiredFields;
}

/**
 * Email and address have to be global variables (set on window)
 * If address doesn't have all required fields, it shouldn't be set at all
 * Required fields for address: 'first_name', 'last_name', 'postal_code', 'country'
 * If a field is undefined, it shouldn't be set at all
 */
const prepareDataForGADS = (accountDetails, exponeaEmail) => {
  const userData = {
    email: accountDetails?.email || (exponeaEmail !== "null" && exponeaEmail),
    first_name: accountDetails?.firstName,
    last_name: accountDetails?.lastName,
    country: accountDetails?.defaultAddress?.countryCodeV2,
    region: accountDetails?.defaultAddress?.province,
    city: accountDetails?.defaultAddress?.city,
    postal_code: accountDetails?.defaultAddress?.zip,
  };

  const relevantUserDataAddress = {};

  if (isBrowser) {
    if (window.email === undefined && userData.email !== undefined) {
      window.email = userData.email;

      // If a field is undefined, we shouldn't set it
      Object.keys(userData).map((key) => {
        if (!!userData[key] && key !== "email") {
          relevantUserDataAddress[key] = userData[key];
        }
      });

      if (hasAddressRequiredFields(relevantUserDataAddress)) {
        window.address = relevantUserDataAddress;
      }
    }
  }
};

export function trackPageView() {
  fbSendPageView();
  pinSendPageVisit();
  elevarSendPageView();
  retentionSendPageView();
  blotoutSendPageView();
}

export function useTracking() {
  const { accountDetails } = useAuth();
  const countryCode = useCurrentCountryCode();
  const exponeaEmail = isBrowser && window.exponea?.email_id;

  const email = useMemo(
    () => hashEmail(accountDetails?.email || (exponeaEmail !== "null" && exponeaEmail)),
    [accountDetails?.email, exponeaEmail]
  );
  const { gePriceDetails, clientIp } = useContext(GeoContext);

  const currency = gePriceDetails?.CurrencyCode;

  const api = useMemo(() => {
    const appendGlobalDataGTM = (data) => ({
      ...data,
      user_email: email,
      ...(currency ? { currency } : {}),
    });
    const userData = {
      email,
      client_ip: clientIp,
      first_name: accountDetails?.firstName,
      last_name: accountDetails?.lastName,
      country: accountDetails?.defaultAddress?.countryCodeV2,
      state: accountDetails?.defaultAddress?.province,
      city: accountDetails?.defaultAddress?.city,
      zip: accountDetails?.defaultAddress?.zip,
    };
    const appendGlobalDataFB = (data) => ({
      ...data,
      ...(currency ? { currency } : {}),
      userData,
    });
    prepareDataForGADS(accountDetails, exponeaEmail);

    const shopifyStore = getShopifyStoreByCountry(gePriceDetails?.CountryCode);
    const userToken = getCookie(`${shopifyStore.name}-accessToken`) || getSessionUserId();
    return {
      trackSearch: ({ query, searchResults }) => {
        gtmSendEvent("search", appendGlobalDataGTM({ search_query: query }));
        fbSendEvent("Search", appendGlobalDataFB({ search_string: query }));
        gtmSendSearchEvent({ query });
        elevarSendSearch({
          products: searchResults.map((p) => p.shopify).slice(0, 15),
          accountDetails,
        });
        blotoutSearch({ query, searchResults });
      },
      trackAddToCart: ({
        price,
        title,
        variantId,
        productId,
        sku,
        category,
        currency,
        quantity,
        productHandle,
        shouldTrackAlgolia,
        index,
        source,
        images,
        checkoutUrl,
      }) => {
        gtmSendAddToCart(
          { price, title, productId, category, currency, quantity },
          appendGlobalDataGTM
        );
        fbSendAddToCart(
          { price, title, productId, category, currency, quantity },
          appendGlobalDataFB
        );
        juSendAddToCart({ productId, variantId, sku, title, quantity, price });
        pinSendAddToCart({ category, currency, price, productId, variantId, quantity, title });
        elevarSendAddToCart({
          category,
          currency,
          price,
          productId,
          variantId,
          quantity,
          title,
          sku,
          accountDetails,
          images,
        });
        if (shouldTrackAlgolia) {
          algoliaProductAddedToCart(userToken, index, productHandle);
        }
        if (source === ComponentType.FLOATING_ATC) {
          expSendFloatingAddToCart({
            price,
            title,
            variantId,
            productId,
            sku,
            category,
            currency,
          });
        }
        retentionSendAddToCart({
          price,
          title,
          productId,
          category,
          source,
          productHandle,
          currency,
          images,
        });
        bingSendAddToCart({ productId, currency, price, quantity });
        blotoutSendAddToCart({
          price,
          title,
          variantId,
          productId,
          sku,
          category,
          currency,
          quantity,
          productHandle,
          shouldTrackAlgolia,
          index,
          source,
          images,
          checkoutUrl,
        });
      },
      trackAddToCartExponea: ({
        product_ids,
        product_list,
        sku_ids,
        sku_list,
        variant_ids,
        variant_list,
        total_price,
        total_price_local_currency,
        total_quantity,
      }) => {
        expTrackAddToCart({
          product_ids,
          product_list,
          sku_ids,
          sku_list,
          variant_ids,
          variant_list,
          total_price,
          total_price_local_currency,
          total_quantity,
        });
      },
      trackRemoveFromCart: ({ lineItem, price, title, productId, category, quantity }) => {
        gtmSendRemoveFromCart({ price, title, productId, category, currency, quantity });

        elevarSendRemoveFromCart({
          lineItem,
          title,
          productId,
          category,
          currency,
          quantity,
          accountDetails,
        });

        expTrackRemoveFromCart({ productId, title, price, currency, category, quantity });
      },
      trackProductClick: ({ price, title, productId, category }) => {
        gtmSendProductClick({ price, title, productId, category });
      },
      trackViewCollectionPage: ({ collectionId, url, products, name }) => {
        const collectionIdCopy = collectionId.replace("gid://shopify/Collection/", "");
        gtmSendViewCollectionPage(
          { currency, collectionId: collectionIdCopy, url, products },
          appendGlobalDataGTM
        );
        pinSendViewCategory();
        elevarSendViewPLP({ url, products: products.slice(0, 15), accountDetails });
        bingViewCollection({ collectionId: collectionIdCopy, products });
        retentionViewCollection({ url, name });
      },
      trackViewProductPage: ({ price, title, productType, productId, product }) => {
        gtmSendViewProductPage({ price, title, productType, productId }, appendGlobalDataGTM);
        fbSendViewProductPage({ price, title, productType, productId }, appendGlobalDataFB);
        expSendViewProductPage({ price, title, productType, productId });
        pinSendViewProductPage({ title, productId, productType, price });
        elevarViewProduct({ product, accountDetails });
        bingViewProduct({ product });
        retentionViewProduct({ product });
        blotoutViewProduct({ product });
      },
      trackAddToWishlist: ({ type, productHandle, productName, price, productId }) => {
        gtmSendAddToWishlist(
          { productId, price, currency, type, productName },
          appendGlobalDataGTM
        );
        fbSendAddToWishlist({ productId, productHandle, price }, appendGlobalDataFB);

        expSendAddToWishlist({ productHandle, price, productId, email });
      },
      trackRemoveFromWishlist: ({ productHandle, price, productId }) => {
        expSendRemoveFromWishlist({ productHandle, price, productId, email });
      },
      trackUserSignup: (customerEmail, httpStatus, data, isSuccessful) => {
        if (isSuccessful) {
          const hashedEmail = hashEmail(customerEmail);
          fbSendUserSignup(hashedEmail, appendGlobalDataFB);
        }
        // We want to track Exponea event even on failed signups
        expSendUserSignup({ email: customerEmail, httpStatus, data });
        gtmSendSignUp();
        elevarSendSignUp({ email: customerEmail });
      },
      trackUserLogin: (customerEmail) => {
        expSendUserLogin({ email: customerEmail });
        elevarSendLogin({ email: customerEmail });
      },
      trackCountryUpdate: (country) => {
        expTrackCountryUpdate({ country });
      },
      trackRecommendedProduct(action, sectionTitle, { productTitle, productId, productCategory }) {
        expTrackRecommendedProduct(action, {
          sectionTitle,
          productTitle,
          productId,
          productCategory,
        });
      },
      trackOpenSideCart(cart) {
        juViewSideCart(cart, currency);
        expSendSideCartOpen({ cart, currency });
        gtmSendViewCart({ cart, currency });
        elevarSendViewCart({ cart, currency, accountDetails });
        bingViewCart({ cart, currency });
      },
      trackBundleUpdate: ({ action, handle, price, currency, productId, step }) => {
        expTrackBundleUpdate({
          eventName: action,
          handle,
          price,
          currency,
          productId,
          step,
        });
        if (action === "add_product_bundle") {
          gtmSendBundleUpdate({ handle, price, currency, productId, step }, appendGlobalDataGTM);
        }
      },
      trackAddBundleToCart: ({ fullPrice, finalPrice, products }) => {
        expTrackAddBundleToCart({
          fullPrice,
          finalPrice,
          currency,
          products,
        });
        gtmSendAddBundleToCart({ fullPrice, finalPrice, currency, products }, appendGlobalDataGTM);
      },
      trackBundleItems: ({ numberItems, rating, fullPrice, finalPrice, products }) => {
        expTrackBundleItems({
          numberItems,
          rating,
          fullPrice,
          finalPrice,
          currency,
          products,
        });
      },
      trackClickAlternative: ({
        page,
        activeProductHandle,
        activeProductColor,
        alternativeHandle,
        alternativeColor,
      }) => {
        expTrackClickAlternative({
          page,
          activeProductHandle,
          activeProductColor,
          alternativeHandle,
          alternativeColor,
        });
      },
      trackDropHintOpenPopup: ({ pageUrl }) => {
        expSendDropHintOpenPopup({ pageUrl });
      },
      trackDropHintClosePopup: ({ pageUrl }) => {
        expSendDropHintClosePopup({ pageUrl });
      },
      trackDropHintIconClick: ({ pageUrl, socialIcon }) => {
        expSendDropHintIconClick({ pageUrl, socialIcon });
      },
      trackProductClickAfterSearch: (indexName, queryId, objectID, position) => {
        // TODO: dl_select_item
        algoliaProductClickAfterSearch(userToken, indexName, queryId, [objectID], [position]);
      },
      trackAddToCartAfterSearch: (index, queryID, objectID) => {
        algoliaConvertedObjectIDsAfterSearch(userToken, index, queryID, objectID);
      },
      trackBackToTopClick: (pageUrl) => {
        expSendBackToTopClick({ pageUrl });
      },
      trackMenuClick: ({
        destinationUrl,
        menuTitle,
        categoryTitle,
        subCategoryTitle,
        sourceType,
      }) => {
        const eventLabel = subCategoryTitle
          ? `${menuTitle} -> ${categoryTitle} -> ${subCategoryTitle}`
          : `${menuTitle} -> ${categoryTitle}`;
        gaCustomEvent("Menu Link", "Click", eventLabel);
        expSendMenuClick({
          destinationUrl,
          menuTitle,
          categoryTitle,
          subCategoryTitle,
          sourceType,
        });
      },
      trackCollectionFilterClick: (category, title, turningOn) => {
        expSendCollectionFilterClick({ category, title, turningOn });
      },
      trackCollectionSortClick: (value) => {
        expSendCollectionSortClick({ value });
      },
      trackAddPreBundledProductToCart: ({
        bundleName,
        cpPosition,
        fullPrice,
        finalPrice,
        products,
      }) => {
        expTrackAddPreBundledProductToCart({
          bundleName,
          cpPosition,
          fullPrice,
          finalPrice,
          products,
        });
      },
      trackQuickViewClick: ({
        productHandle,
        productTitle,
        productPrice,
        currencyCode,
        collectionHandle,
        cpPosition,
        product,
      }) => {
        expTrackQuickViewClick({
          productHandle,
          productTitle,
          productPrice,
          collectionHandle,
          cpPosition,
          countryCode,
          product,
        });
        elevarViewProduct({ product, accountDetails });
        blotoutQuickViewProduct({
          productHandle,
          productTitle,
          productPrice,
          currencyCode,
          collectionHandle,
          cpPosition,
          countryCode,
          product,
        });
      },
      trackViewProductDetailsOnByob: ({ handle, id, title, price, category }) => {
        expTrackViewProductDetailsOnByob({
          handle,
          id,
          title,
          price,
          category,
        });
      },
      trackPLPCardClick: ({ collectionHandle, link, title }) => {
        expTrackCollectionCards({
          collectionHandle,
          link,
          title,
        });
      },
      trackSubscriptionCancel: (reason) => {
        expTrackSubscriptionCancel(reason);
      },
      trackSubscriptionSkip: () => {
        expTrackSubscriptionSkip();
      },
      trackSubscriptionPause: (interval) => {
        expTrackSubscriptionPause(interval);
      },
      trackSubscriptionReactivate: () => {
        expTrackSubscriptionReactivate();
      },
      trackOptionalProductsDrawerOpen: ({ originProductTitle }) => {
        expOptionalProductsDrawerOpen({ originProductTitle });
      },
      trackOptionalProductsDrawerAdd: ({ originProductTitle, handle, price, currency }) => {
        expOptionalProductsDrawerAdd({ originProductTitle, handle, price, currency });
      },
      trackOptionalProductsDrawerRemove: ({ originProductTitle, handle, price, currency }) => {
        expOptionalProductsDrawerRemove({ originProductTitle, handle, price, currency });
      },
      trackOptionalProductsDrawerEdit: ({ originProductTitle, addedHandles, price, currency }) => {
        expOptionalProductsDrawerEdit({ originProductTitle, addedHandles, price, currency });
      },
      trackOptionalProductsDrawerConfirmation: ({
        originProductTitle,
        handles,
        price,
        currency,
      }) => {
        expOptionalProductsDrawerConfirmation({ originProductTitle, handles, price, currency });
      },
      trackAccountErrors: ({ action, errorMessage, userEmail }) => {
        const customerEmail = userEmail || email;
        expSendAccountError({ action, errorMessage, email: customerEmail });
      },
    };
  }, [email, currency, accountDetails, countryCode, clientIp]);
  return api;
}
