import useGetProductsByCollection from "../../hooks/useGetProductsByCollection";

import { withALErrorBoundary } from "../../helpers/ErrorBoundary/ALErrorBoundary";

import { ALLoading } from "../ALComponents";
import RecommendedProducts from "../RecommendedProducts";

import * as browseProductsStyles from "./browseProductsProduct/browseProductsStyles.module.scss";
import "./browseProducts/browseProducts.scss";

function BrowseProducts({ content, handleProductClick, componentType }) {
  const handles = useGetProductsByCollection(content.collectionHandle);
  const additionalClassName = componentType ? componentType.toLowerCase() : "";

  if (content) {
    return (
      <div
        className={`browse_product_container ${browseProductsStyles.container} ${additionalClassName} id-${content.contentful_id}`}
      >
        {handles && handles.length > 0 ? (
          <RecommendedProducts
            handles={handles}
            collection={content.collectionHandle}
            title={content.title}
            handleProductClick={handleProductClick}
            componentType={componentType}
          />
        ) : (
          <p className="al_p">Loading...</p>
        )}
      </div>
    );
  }
  return (
    <div className="browse_product_loading">
      <ALLoading />
      <p className="al_p">Loading products...</p>
    </div>
  );
}

export default withALErrorBoundary({
  name: "BrowseProducts",
  priority: "P2",
})(BrowseProducts);
